import { useLayoutEffect } from 'react';
import { ValidationUtils } from '../utils';

export function useSegmentLoader(): void {
    const segmentKey = ValidationUtils.validateAndGetEnvVar('REACT_APP_SEGMENT_KEY');

    const loadSegmentScript = () => {
        const analytics = ((window as any).analytics = (window as any).analytics || []);
        if (!analytics.initialize) {
            if (analytics.invoked) {
                // tslint:disable-next-line:no-unused-expression
                (window as any).console && console.error && console.error('Segment snippet included twice.');
            } else {
                analytics.invoked = !0;
                analytics.methods = [
                    'trackSubmit',
                    'trackClick',
                    'trackLink',
                    'trackForm',
                    'pageview',
                    'identify',
                    'reset',
                    'group',
                    'track',
                    'ready',
                    'alias',
                    'debug',
                    'page',
                    'once',
                    'off',
                    'on',
                ];
                analytics.factory = (arg: any) => {
                    // tslint:disable-next-line:only-arrow-functions
                    return function() {
                        const e = Array.prototype.slice.call(arguments);
                        e.unshift(arg);
                        analytics.push(e);
                        return analytics;
                    };
                };

                for (const e of analytics.methods) {
                    analytics[e] = analytics.factory(e);
                }

                analytics.load = (key: string, options?: any) => {
                    const scriptElement = document.createElement('script');
                    scriptElement.type = 'text/javascript';
                    scriptElement.async = !0;
                    scriptElement.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

                    const firstScriptElement = document.getElementsByTagName('script')[0];
                    if (firstScriptElement && firstScriptElement.parentNode !== null) {
                        firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
                    }
                    analytics._loadOptions = options;
                };
                analytics.SNIPPET_VERSION = '4.1.0';
                analytics.load(segmentKey);
            }
        }
    };

    useLayoutEffect(() => {
        loadSegmentScript();
    }, []);
}

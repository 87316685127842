import { useEffect, useState } from 'react';
import segmentService from '../services/segment/segmentService';
import ddLogger from '../logging/ddLogger';
import { useSelector } from 'react-redux';
import { StoreState } from '../types/store.types';

export function usePageViewTracker(): void {
    const { user: store } = useSelector((storeState: StoreState) => storeState);
    const [isPageTracked, setIsPageTracked] = useState(false);

    useEffect(() => {
        if (!isPageTracked && store.user) {
            const pathname = window.location.pathname;
            segmentService.trackPageView();
            ddLogger.info(`PageView - ${pathname}`, { pathname });
            setIsPageTracked(true);
        }
    }, [store]);
}

import axios, { AxiosError } from 'axios';
import * as _ from 'lodash';
import { ValidationUtils } from '../../utils';
import { Trip, TripsLite } from '../../types/trip.type';
import { CookieKeys } from '../../types/keys.type';
import Cookies from 'js-cookie';
import { InitialLanguage } from '../../types/preference.type';
import { DetailedError, ErrorsPrefix } from '../../types/errors.type';
import * as Sentry from '@sentry/browser';
import { logPerformance } from '../../logging/logPerformance.decorator';
import ddLogger from '../../logging/ddLogger';
import { Configuration } from '../../configuration';

export class TripService {
    public static shouldIgnoreError(message: string): boolean {
        if (_.isEmpty(message)) {
            return true;
        }
        const errorsToIgnore = new RegExp(Configuration.ignoreErrorList.join('|'));

        return errorsToIgnore.test(message);
    }

    private static getHeaders(accessToken: string) {
        const language = (Cookies.get(CookieKeys.PREFERRED_LANGUAGE) as any) || InitialLanguage.code;

        return {
            'Content-Type': 'application/vnd.api+json',
            'Accept-Language': language,
            Authorization: `Bearer ${accessToken}`,
        };
    }

    private static getDateTZ(date: Date) {
        let timezone = '';
        try {
            // @ts-ignore
            timezone = date.toString().match(/([-\+][0-9]+)\s/)[1];
            timezone = timezone.substr(0, 3) + ':' + timezone.substr(3);
        } catch (e) {
            ddLogger.error(`failed parsing timezone: ${date.toString()}`);
        }
        return timezone;
    }

    private url = ValidationUtils.validateAndGetEnvVar('REACT_APP_TRIP_API_URL');

    @logPerformance()
    public async getTrips(accessToken: string): Promise<TripsLite> {
        try {
            const headers = TripService.getHeaders(accessToken);
            const { data } = await axios.get(`${this.url}/get-trips`, { headers });
            return data.data;
        } catch (e) {
            throw this.makeAndCaptureDetailedError('getting trips', e);
        }
    }

    @logPerformance((instance, args) => `reservationId ${args[0]}`)
    public async getTripByReservationId(reservationId: string, accessToken: string): Promise<Trip> {
        try {
            const headers = TripService.getHeaders(accessToken);
            const { data } = await axios.get(`${this.url}/trip/${reservationId}`, { headers });
            const trip = data.data as Trip;

            const atNoon = 'T12:00:00';

            if (trip && trip.attributes && trip.attributes.stay) {
                const currentTimezone = TripService.getDateTZ(new Date());

                trip.attributes.stay.check_in_date_time += TripService.getDateTZ(
                    new Date(`${trip.attributes.stay.check_in_date}${atNoon}${currentTimezone}`)
                );
                trip.attributes.stay.check_out_date_time += TripService.getDateTZ(
                    new Date(`${trip.attributes.stay.check_out_date}${atNoon}${currentTimezone}`)
                );
            }

            return trip;
        } catch (e) {
            throw this.makeAndCaptureDetailedError('getting trip', e);
        }
    }

    @logPerformance((instance, args) => `id ${args[0]}, last_name ${args[1]}`)
    public async addTripByIdAndLastName(
        id: string,
        last_name: string,
        accessToken: string
    ): Promise<{ attributes: { reservation_id: string; confirmation_code?: string } }> {
        try {
            const headers = TripService.getHeaders(accessToken);
            const { data } = await axios.post(
                `${this.url}/add-trip/`,
                {
                    data: {
                        type: 'reservation',
                        attributes: {
                            id,
                            last_name,
                        },
                    },
                },
                { headers }
            );

            return data.data;
        } catch (e) {
            throw this.makeAndCaptureDetailedError('adding trip', e);
        }
    }

    private makeAndCaptureDetailedError(tag: string, e: AxiosError): DetailedError {
        let message: string;
        let status = 0;
        const { name, stack } = e;
        let prefix: string = '';

        if (e.response) {
            const { statusText, data } = e.response;
            status = e.response.status;
            prefix = TripService.getErrorPrefix(status);

            const messages = _.map(data.errors, (err) => {
                return `${tag} STATUS ${err.status || status} - ${err.code || err.title} ${err.detail ? `- DETAIL: ${err.detail}` : ''}`;
            });

            message = messages.length > 0 ? messages.join(',') : `${tag} STATUS ${status} - ${statusText}`;
        } else {
            message = `${tag} STATUS ${status} - ${e?.message}`;
        }

        const exception: DetailedError = { name, message, status, stack, errorPrefix: prefix };

        if (!TripService.shouldIgnoreError(message) || (stack && !TripService.shouldIgnoreError(stack))) {
            Sentry.captureException(new Error(exception.message));
        }

        return exception;
    }

    static getErrorPrefix(code: string | number): string {
        let errorPrefix: string = ErrorsPrefix.TRIP_API_ERROR;
        // @ts-ignore
        if (ErrorsPrefix[code]) {
            // @ts-ignore
            errorPrefix = ErrorsPrefix[code];
        }
        if (code === 0) {
            errorPrefix = ErrorsPrefix.NETWORK_ERROR;
        }
        return errorPrefix;
    }
}

export default new TripService();

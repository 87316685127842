import Cookies from 'js-cookie';
import { UserActions, UserActionTypes, UserState } from '../../types/user.store.type';
import { Language, Currency, LanguageCodes, InitialLanguage } from '../../types/preference.type';
import { CookieKeys } from '../../types/keys.type';
import languages from '../../assets/languages.json';
import currencies from '../../assets/currencies.json';
import { ValidationUtils } from '../../utils';

const storedLanguageCode = Cookies.get(CookieKeys.PREFERRED_LANGUAGE);
const storedCurrency = Cookies.get(CookieKeys.PREFERRED_CURRENCY) as Currency;

const language = languages.find((lang) => lang.code === storedLanguageCode);
const currency = currencies.find((curr) => curr === storedCurrency);

let initialLanguage: Language;
let initialCurrency: Currency;

if (language) {
    initialLanguage = {
        id: language.id,
        name: language.name,
        code: language.code as LanguageCodes,
    };
} else {
    initialLanguage = InitialLanguage;
    Cookies.set(CookieKeys.PREFERRED_LANGUAGE, InitialLanguage.code, { domain: `${ValidationUtils.getCookieDomain()}` });
}

if (currency) {
    initialCurrency = currency as Currency;
} else {
    initialCurrency = Currency.USD;
    Cookies.set(CookieKeys.PREFERRED_CURRENCY, initialCurrency, { domain: `${ValidationUtils.getCookieDomain()}` });
}

const initialState: UserState = {
    user: null,
    isSessionExpired: false,
    language: initialLanguage,
    currency: initialCurrency,
    hidePage: true,
};

export function userReducer(state = initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case UserActions.LOGIN: {
            return { ...state, user: action.payload };
        }

        case UserActions.LOGOUT: {
            return { ...state, user: null };
        }

        case UserActions.SESSION_EXPIRED: {
            return { ...state, isSessionExpired: action.payload };
        }

        case UserActions.SET_CURRENCY: {
            Cookies.set(CookieKeys.PREFERRED_CURRENCY, action.payload, { domain: `${ValidationUtils.getCookieDomain()}` });
            return { ...state, currency: action.payload };
        }

        case UserActions.SET_LANGUAGE: {
            Cookies.set(CookieKeys.PREFERRED_LANGUAGE, action.payload.code, { domain: `${ValidationUtils.getCookieDomain()}` });
            return { ...state, language: action.payload };
        }

        case UserActions.SET_HIDE_PAGE: {
            return { ...state, hidePage: action.payload };
        }

        default: {
            return state;
        }
    }
}

import React from 'react';
import { TranslationUtils } from '../../utils';
import { Stay, Review } from 'guest-data-types-ts-lib';
import { TripCardVariant } from '..';
import { IconClock, IconArrowRightTwo, IconCalendar, IconStar, IconCheckMark } from '../../assets/icons';
import './NavigationPanel.scss';
import moment from 'moment';

export enum NavigationPanelTypes {
    LATE_CHECKOUT_PURCHASED = 'LATE_CHECKOUT_PURCHASED',
    EARLY_CHECKIN = 'EARLY_CHECKIN',
    LATE_CHECKOUT = 'LATE_CHECKOUT',
    ADD_REVIEW = 'ADD_REVIEW',
    BOOK_AGAIN = 'BOOK_AGAIN',
}

interface NavigationPanelProps {
    stay: Stay;
    review: Review;
    variant: TripCardVariant;
}

export const NavigationPanel: React.FC<NavigationPanelProps> = (props) => {
    const { stay, review, variant } = props;

    const options = {
        EARLY_CHECKIN: {
            title: TranslationUtils.get('early_checkout_available'),
            icon: <IconClock className="card-stay-panel-footer-left-icon" />,
        },
        ADD_REVIEW: {
            title: TranslationUtils.get('add_review'),
            icon: <IconStar className="card-stay-panel-star-icon" />,
        },
        BOOK_AGAIN: {
            title: TranslationUtils.get('book_again'),
            icon: <IconCalendar className="card-stay-panel-calendar-icon" />,
        },
        LATE_CHECKOUT: {
            title: TranslationUtils.get('late_checkout'),
            icon: <IconClock className="card-stay-panel-footer-left-icon" />,
        },
        LATE_CHECKOUT_PURCHASED: {
            title: TranslationUtils.get('late_checkout_purchased'),
            icon: <IconCheckMark className="card-stay-panel-footer-left-icon card-stay-panel-check-icon" />,
        },
    };

    const getNavigationPanelType = () => {
        switch (variant) {
            case TripCardVariant.PAST_TRIP:
                return review && review.is_eligible && review.rating === null
                    ? NavigationPanelTypes.ADD_REVIEW
                    : NavigationPanelTypes.BOOK_AGAIN;

            case TripCardVariant.UPCOMING_TRIP:
            case TripCardVariant.CURRENT_TRIP:
                if (stay.late_check_out_purchased) {
                    return NavigationPanelTypes.LATE_CHECKOUT_PURCHASED;
                }

                if (stay.late_check_out_available) {
                    return NavigationPanelTypes.LATE_CHECKOUT;
                }

                return stay.early_check_in && moment().isSameOrBefore(stay.check_out_date_time, 'date')
                    ? NavigationPanelTypes.EARLY_CHECKIN
                    : null;

            default:
                return null;
        }
    };

    const option = getNavigationPanelType();
    if (option === null) {
        return <React.Fragment />;
    }

    return (
        <div id={'button-action-past-trip'} className="alert alert-secondary card-stay-panel-footer">
            <div className="row">
                <div className="col-2 col-md-2">{options[option].icon}</div>
                <div className="col-8 col-md-8 pl-0">
                    <p className="card-stay-panel-footer-content body-small-bold"> {options[option].title}</p>
                </div>
                <div className="col-2 col-md-2">
                    <IconArrowRightTwo className="card-stay-panel-footer-right-icon" />
                </div>
            </div>
        </div>
    );
};

import {useDecision} from "@optimizely/react-sdk";
import {FeatureDecisionResult, FeatureFlag} from "../../types/featureFlag.type";

export const useFeatureConfig = <T>(featureKey: FeatureFlag, autoUpdate: boolean): FeatureDecisionResult<T> => {
    const [decision, clientReady] = useDecision(featureKey, { autoUpdate });
    const { enabled: isEnabled, variables } = decision;
    const config = variables?.config as T | null;

    return { isEnabled, config, clientReady };
};

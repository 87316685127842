import { AppStrings } from '../messages';

export const Italian: AppStrings = {
    language_and_currency: 'Lingua',

    property_management: 'Gestione Delle case Vacanze',
    real_estate: 'Immobiliare',
    company: 'Azienda',
    innovation: 'Innovazione',
    community: 'Comunità',
    about_us: 'Chi siamo',
    careers: 'Opportunità di carriera',
    newsroom: 'Sala stampa',
    investors: 'Investitori',
    blog: 'Blog',
    solutions_partnerships: 'Soluzioni e partnership',
    accessibility: 'Accessibilità',
    leadership: 'Comando',
    social_responsibility: 'Responsabilità sociale',

    help: 'Aiuto',
    support_contact: 'Assistenza e contatti',
    toll_free: 'Numero Verde:',
    local: 'Locale:',
    covid_update: 'COVID-19 Updates',
    cancel_my_trip: 'Annulla Il Mio Viaggio',

    my_account: 'Accedi / Registrati',
    hi: 'Salve, ',
    profile: 'Profilo',
    favorites: 'Preferiti',
    login: 'Accesso / Registrazione Ospite',
    logout: 'Esci',
    find_my_trip: 'Trova il mio viaggio',
    find_your_trip: 'Encontre sua viagem',
    my_trips: 'I miei viaggi',
    owner_login: 'Account Proprietario',

    sitemap: 'Mappa del sito',
    privacy: 'Privacy',
    terms_of_service: 'Termini del servizio',

    your_vacation_full_time_job: 'La tua vacanza è il nostro obiettivo principale',
    your_vacation_full_time_job_note:
        'Quando prenoti con Vacasa, hai più di una semplice casa vacanze. Hai un servizio professionale completo su cui poter contare.',

    not_trip_found: 'Nessun viaggio trovato',
    not_reservations_found_for: 'Non abbiamo trovato prenotazioni per',
    book_a_trip: 'Prenota un viaggio',

    confirmation: 'Conferma',
    last_name: 'Cognome',
    find_trip_note: "Inserisci il codice presente nell'email di conferma e il cognome associato al tuo viaggio.",
    add_trip: 'Aggiungi viaggio',
    not_correct_trip_info_note: 'Le informazioni sul viaggio non sono corrette. Ti invitiamo a controllare e riprovare.',
    dont_see_your_trip: 'Non vedi il tuo viaggio?',
    dont_see_your_trip_note: 'Aggiungilo utilizzando il codice presente nella tua email di conferma.',
    past_trips: 'Viaggi precedenti',
    current_trip: 'Viaggio attuale',
    need_help: 'Ti serve aiuto?',

    early_checkout_available: 'Early check-in è disponibile!',
    add_review: 'Aggiungi la tua recensione',
    book_again: 'Prenota di nuovo',
    late_checkout: 'Late check-out è disponibile!',
    late_checkout_purchased: 'Hai acquistato late check-out.',

    find_my_trip_note: "Inserisci il codice presente nell'email di conferma e il cognome associato al tuo viaggio.",
    info_is_not_right: 'Le informazioni non sono corrette.',
    check_and_try_again: 'Ti invitiamo a controllare e riprovare.',

    item_needs_attention: 'Articolo richiede attenzione',
    items_need_attention: 'Articoli richiedono attenzione',

    somethings_not_right: 'Qualcosa non va',
    try_later_or_call: 'Ti invitiamo a riprovare più tardi oppure chiama il tuo rappresentante Vacasa al numero',

    or: 'o',

    confirmation_code: 'Codice di conferma',
    need_help_note:
        "Troverai il codice del tuo viaggio nell'e-mail di conferma di Vacasa, insieme al nome e all'indirizzo della casa vacanze. Di solito è una combinazione di 8 lettere e numeri.",
    need_help_note_third_party:
        'Ricorda che devi inserire il codice di Vacasa, perché al momento non possiamo processare codici di terze parti.',
    okay: "D'accordo",

    download_app_mobile_note: 'Il check in è più semplice con l’app di Vacasa!',
    download_app_mobile_title: 'Scarica l’app',

    toll_free_phone_number: '+1 844-818-1180',

    browser_deprecated: 'Browser non supportato',
    please_upgrade_browser: 'Aggiorna o utilizza un altro browser per visualizzare questo sito',

    info_panel: 'Pannello informativo',
    close_info_panel: 'Chiudi pannello informazioni',

    open: 'Open',

    star_rating_title: 'Rivedi il tuo soggiorno',
    star_rating_note: "Com'è stata la tua esperienza presso {unitName}? Il tuo feedback è importante per noi.",
    next: 'Prossimo',
};

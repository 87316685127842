import { AuthUtils, SharedUtils } from '../utils';
import { StorageKeys } from '../types/keys.type';
import { FLOW_ID } from '../App';
import { OptimizelyService } from '../services/optimizely/optimizelyService';
class DDLogger {
    private logger = (window as any).DD_LOGS ? (window as any).DD_LOGS.logger : null;

    public info(message: string, data?: object) {
        if (this.logger) {
            const tracingData = this.getTracingData(data);
            return this.logger.info(message, tracingData);
        }

        console.log(message);
    }

    public warn(message: string, data?: object) {
        if (this.logger) {
            const tracingData = this.getTracingData(data);
            return this.logger.warn(message, tracingData);
        }

        console.log(message);
    }

    public error(message: string, data?: object) {
        if (this.logger) {
            const tracingData = this.getTracingData(data);
            return this.logger.error(message, tracingData);
        }

        console.error(message);
    }

    private getTracingData = (data: { [key: string]: any } = {}) => {
        const optimizelyStatus = OptimizelyService.getInstance().isValid();
        const identityToken = AuthUtils.getIdentityToken();
        const accessToken = SharedUtils.getSessionStorageItem(StorageKeys.ACCESS_TOKEN) || 'empty';

        const userId = identityToken && identityToken.sub ? identityToken.sub : 'empty';

        const auth = {
            userId,
            accessToken: accessToken.substr(0, 15),
            isAccessTokenValid: !AuthUtils.isTokenExpired(accessToken),
        };
        return { ...data, flowId: FLOW_ID, optimizelyStatus, auth };
    };
}

export default new DDLogger();

import React, { useState, useLayoutEffect } from 'react';
import { setHidePage, sessionExpired, logIn, setIsShowingFindMyTripModal } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AuthUtils, ValidationUtils } from '../utils';
import { UserState } from '../types/user.store.type';
import { IdentityToken, User, AccessToken } from '../types/auth.type';
import { FIND_MY_TRIP_MODAL } from '../components';
import ddLogger from '../logging/ddLogger';
import { useSegmentLoader } from '../hooks';
import moment from 'moment';
import decode from 'jwt-decode';

export const Authentication: React.FC = () => {
    const storeUser: UserState = useSelector((store: { user: UserState }) => store.user);
    const [urlIframe, setUrlIframe] = useState('');
    const dispatch = useDispatch();
    useSegmentLoader();
    const handleAccessAndIdentityToken = (auth: any) => {
        const storedAccessToken = AuthUtils.getAccessToken();
        const storedIdentityToken = AuthUtils.getIdentityToken();
        if (storedAccessToken && storedIdentityToken) {
            const newIdToken = AuthUtils.decodeToken(auth.id_token) as IdentityToken;
            if (storedIdentityToken.email !== newIdToken.email) {
                // session changed
                dispatch(sessionExpired(true));
            }
        }

        // TODO why user is always null
        if (storeUser.user === null) {
            const idToken = AuthUtils.decodeToken(auth.id_token) as IdentityToken;
            const currentUser: User = {
                id: idToken.sub,
                name: idToken.given_name,
                email: idToken.email,
                accessToken: auth.access_token,
            };
            dispatch(logIn(currentUser));
        }

        AuthUtils.storeAccessToken(auth.access_token);
        AuthUtils.storeIdentityToken(auth.id_token);
        dispatch(setHidePage(false));
    };

    const handleIframeAuth = (e: any) => {
        if (e.origin !== window.location.origin || typeof e.data !== 'object' || e.data.type !== 'token_refresh') {
            return;
        }

        const { payload } = e.data;

        // TODO TRP-454 restore session expired functionality
        // If IDP response has an error and user was previously authenticated, we should expire session
        // if (payload.error && AuthUtils.isAuthenticated()) {
        // dispatch(sessionExpired(true));
        // return;
        // }

        // TODO TRP-454 restore session expired functionality
        // if (payload.error) {
        // console.log("Couldn't refresh the token", payload);
        // AuthUtils.goToIdpLogin();
        // return;
        // }

        if (payload.access_token && payload.id_token) {
            handleAccessAndIdentityToken(payload);
        }
    };

    useLayoutEffect(() => {
        if (!AuthUtils.isInsideIFrame()) {
            const authResponse: any = AuthUtils.getAuthFromUrl();
            AuthUtils.removeFragmentFromUrl();
            if (!authResponse) {
                AuthUtils.goToValidateSession();
                return;
            } else {
                if (authResponse.error) {
                    /* This is supposed to be conditioned by a feature flag
                     AuthUtils.goToIdpLogin();
                     return;
                    */
                    AuthUtils.goToMyTripsLogin();
                    return;
                }

                AuthUtils.storeAccessToken(authResponse.access_token);
                AuthUtils.storeIdentityToken(authResponse.id_token);

                if (authResponse.state === FIND_MY_TRIP_MODAL) {
                    dispatch(setIsShowingFindMyTripModal(true));
                }

                const idToken = AuthUtils.decodeToken(authResponse.id_token) as IdentityToken;
                const currentUser: User = {
                    id: idToken.sub,
                    name: idToken.given_name,
                    email: idToken.email,
                    accessToken: authResponse.access_token,
                };

                dispatch(logIn(currentUser));
                ddLogger.info(`Login successful`, { userId: currentUser.id });

                (window as any).dataLayer = (window as any).dataLayer || [];
                (window as any).userDetails = (window as any).userDetails || {};

                const loginEvent = {
                    event: 'idp login event',
                    id: idToken.sub,
                    idp_id: idToken.sub,
                    idp_email: idToken.email,
                };

                (window as any).dataLayer.push(loginEvent);
                (window as any).userDetails = {
                    userId: idToken.sub,
                    email: idToken.email,
                };

                dispatch(setHidePage(false));
                setTimeout(() => {
                    setUrlIframe(AuthUtils.getLoginRefreshUrl());
                    window.removeEventListener('message', handleIframeAuth);
                    window.addEventListener('message', handleIframeAuth, false);
                }, Number(ValidationUtils.validateAndGetEnvVar('REACT_APP_TOKEN_REFRESH_TIME')));
            }
        } else {
            const iframeAuth = AuthUtils.getAuthFromUrl();

            if (iframeAuth) {
                let timeOut = Number(ValidationUtils.validateAndGetEnvVar('REACT_APP_TOKEN_REFRESH_TIME'));

                window.parent.postMessage({ type: 'token_refresh', payload: iframeAuth }, window.location.origin);
                if (iframeAuth.access_token) {
                    let accessToken = decode(iframeAuth.access_token) as AccessToken;
                    timeOut = moment().diff(moment.unix(accessToken.exp).subtract(60, 'seconds'), 'seconds') * -1000;
                }
                setTimeout(() => {
                    window.location.assign(AuthUtils.getLoginRefreshUrl());
                }, timeOut);
            }
        }
        return () => {
            window.removeEventListener('message', handleIframeAuth);
        };
    }, []);

    if (AuthUtils.isInsideIFrame()) {
        return <React.Fragment />;
    }

    return <iframe id="authFrame" title="token refresh" src={urlIframe} style={{ display: 'none' }} />;
};

export enum CookieKeys {
    PREFERRED_LANGUAGE = 'language',
    PREFERRED_CURRENCY = 'CurrencyDisplay',
    INFO_MODAL_DISMISSED = 'action_info_modal_dismissed',
}

export enum StorageKeys {
    NONCE = 'nonce',
    STATE = 'state',
    LEGACY_TOKEN = 'legacy_token',
    ACCESS_TOKEN = 'access_token',
    IDENTITY_TOKEN = 'id_token',
    CACHED_TRIPS = 'cached_trips',
}

export enum LanguageCodes {
    EN_US = 'en',
    ES_LA = 'es-419',
}

export interface Language {
    id: number;
    name: string;
    code: LanguageCodes;
}

export const InitialLanguage = {
    id: 40,
    name: 'English',
    code: LanguageCodes.EN_US,
};

export enum Currency {
    USD = 'USD',
    ARS = 'ARS',
    AED = 'AED',
    AUD = 'AUD',
    BGN = 'BGN',
    BRL = 'BRL',
    BZD = 'BZD',
    CAD = 'CAD',
    CHF = 'CHF',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    CRC = 'CRC',
    CUP = 'CUP',
    CZK = 'CZK',
    DKK = 'DKK',
    DOP = 'DOP',
    JPY = 'JPY',
    KRW = 'KRW',
    MXN = 'MXN',
    MYR = 'MYR',
    NOK = 'NOK',
    NZD = 'NZD',
    PEN = 'PEN',
    PHP = 'PHP',
    RON = 'RON',
    RUB = 'RUB',
    SEK = 'SEK',
    SGD = 'SGD',
    THB = 'THB',
    TWD = 'TWD',
    UYU = 'UYU',
    VND = 'VND',
    ZAR = 'ZAR',
}

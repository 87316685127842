import tripService from '../../services/trip/tripService';
import { Asynchronizer, AuthUtils, SharedUtils, ValidationUtils } from '../../utils';
import { TripsActions } from '../../types/trips.store.type';
import { CachedTrip, TimeMap, Trip } from '../../types/trip.type';
import { Stay } from 'guest-data-types-ts-lib';
import moment from 'moment';
import * as _ from 'lodash';
import { User } from '../../types/auth.type';
import { StorageKeys } from '../../types/keys.type';
import ddLogger from '../../logging/ddLogger';
import { FIND_MY_TRIP_MODAL } from '../../components';
import segmentService from '../../services/segment/segmentService';

export function getTripTimeMap(stay: Stay): TimeMap | null {
    if (!stay) {
        ddLogger.warn('WARNING: trip not time-mapped - stay is null');
        return null;
    }

    const today = moment();
    const checkIn = moment(stay.check_in_date_time);
    const checkOut = moment(stay.check_out_date_time);

    if (checkOut.isBefore(today, 'date')) {
        return TimeMap.PAST;
    }
    if (checkIn.isAfter(today, 'date')) {
        return TimeMap.UPCOMING;
    }
    return TimeMap.CURRENT;
}

export function fetchTrips(user: User) {
    return async (dispatch: any) => {
        try {
            const updateThresholdDays = +ValidationUtils.validateAndGetEnvVar('REACT_APP_TRIP_UPDATE_THRESHOLD_DAYS');

            dispatch(setFetchTripsError(null));
            dispatch(setFetchTripsLoading(true));

            let trips = await tripService.getTrips(user.accessToken);
            trips = trips.reverse();

            if (_.isEmpty(trips)) {
                dispatch(setFetchTripsLoading(false));
                return;
            }

            const asynchronizer = new Asynchronizer();

            const showTripsThreshold= 3;
            let currentTripCount = 0

            for (const trip of trips) {
                const { reservation_id } = trip.attributes;

                const cachedTripsKey = SharedUtils.generateKey(StorageKeys.CACHED_TRIPS, `${user.email}_${reservation_id}`);
                const cachedTrip = SharedUtils.getLocalStorageItem(cachedTripsKey);

                const cache = (cachedTrip ? await SharedUtils.uncompress(cachedTrip) : {}) as CachedTrip;

                const isCached = !_.isEmpty(cache);
                if (cache?.meta?.hidden) {
                    continue;
                }

                // We do not update the data we have in cache if the checkout date
                // is greater than the value we set in REACT_APP_TRIP_UPDATE_THRESHOLD_DAYS
                if (isCached) {
                    dispatch(setTrip(cache));

                    const cachedDate = new Date(cache.meta?.cache_date);
                    const checkoutDate = new Date(cache.attributes?.stay?.check_out_date);
                    const diffDays = moment(cachedDate).diff(moment(checkoutDate), 'days');
                    if (diffDays >= updateThresholdDays) {
                        currentTripCount++;
                        continue;
                    }
                }

                await asynchronizer.add(
                    tripService
                        .getTripByReservationId(reservation_id, user.accessToken)
                        .then(async (tripFound) => {
                            if (isCached) {
                                dispatch(updateTrip(tripFound));
                            } else {
                                dispatch(setTrip(tripFound));
                            }
                            currentTripCount++;
                            await SharedUtils.saveTripInCache(cachedTripsKey, tripFound, false);
                        })
                        .catch(async (e) => {
                            ddLogger.error(`ERROR fetching trip by reservationId ${reservation_id}: ${e?.message}`);
                            if (e?.message.includes('RESERVATION_CANCELLED')) {
                                await SharedUtils.saveTripInCache(
                                    cachedTripsKey,
                                    {
                                        id: reservation_id,
                                        attributes: {},
                                    } as Trip,
                                    true
                                );
                                dispatch(removeCancelledReservation(reservation_id));
                            }
                        })
                );

                if(currentTripCount >= showTripsThreshold) {
                    dispatch(setFetchTripsLoading(false));
                }
            }
            await asynchronizer.waitForRemainingPromises();

            dispatch(setFetchTripsLoading(false));
            dispatch(updateUpcomingTrips());
        } catch (e) {
            dispatch(setFetchTripsError(e));
        }
    };
}

export function setFetchTripsLoading(isLoading: boolean) {
    return {
        type: TripsActions.SET_FETCH_TRIPS_LOADING,
        payload: isLoading,
    };
}

export function setFetchTripsError(error: Error | null) {
    return {
        type: TripsActions.SET_FETCH_TRIPS_ERROR,
        payload: error,
    };
}

export function setTripsLoaded(loaded: boolean) {
    return {
        type: TripsActions.SET_TRIPS_LOADED,
        payload: loaded,
    };
}

export function setTrip(trip: Trip) {
    const { stay } = trip.attributes;
    const type = getTripTimeMap(stay);
    if (type) {
        return {
            type: TripsActions.SET_TRIP,
            payload: { type, trip },
        };
    }
}

export function updateTrip(trip: Trip) {
    const { stay } = trip.attributes;
    const type = getTripTimeMap(stay);
    if (type) {
        return {
            type: TripsActions.UPDATE_TRIP,
            payload: { type, trip },
        };
    }
}

export function setIsShowingFindMyTripModal(shouldShow: boolean) {
    if (shouldShow) {
        const accessToken = AuthUtils.getDecodedAccessToken();
        if (!accessToken || AuthUtils.isTokenExpired(accessToken)) {
            const url = AuthUtils.getLoginRefreshUrl(FIND_MY_TRIP_MODAL);
            window.location.assign(url);
            return { type: 'null' };
        }
    }
    return {
        type: TripsActions.SET_IS_SHOWING_FIND_MY_TRIP_MODAL,
        payload: shouldShow,
    };
}

export function addTrip(id: string, lastName: string, user: User) {
    return async (dispatch: any) => {
        dispatch(setAddTripError(null));
        dispatch(setAddTripLoading(true));
        try {
            const { attributes } = await tripService.addTripByIdAndLastName(id, lastName, user.accessToken);
            const reservationId = attributes.reservation_id;
            const confirmationCode = attributes.confirmation_code;
            const newTrip = await tripService.getTripByReservationId(reservationId, user.accessToken);
            ddLogger.info(`Trip added successfully. reservationId: ${reservationId}, confirmationCode: ${confirmationCode}`, {
                reservation_id: reservationId,
                confirmation_code: confirmationCode,
                user_id: user.id,
            });
            segmentService.trackEvent('My Trips', {
                action: 'trip_added',
                action_success: true,
                reservation_id: reservationId,
                confirmation_code: confirmationCode,
            });
            dispatch(setTrip(newTrip));
            dispatch(setAddTripLoading(false));
            const cachedTripsKey = SharedUtils.generateKey(StorageKeys.CACHED_TRIPS, `${user.email}_${reservationId}`);
            await SharedUtils.saveTripInCache(cachedTripsKey, newTrip, false);
            dispatch(updateUpcomingTrips());
            dispatch(setIsShowingFindMyTripModal(false));
        } catch (e) {
            segmentService.trackEvent('My Trips', {
                action: 'trip_added',
                action_success: false,
                reservation_id: id,
                confirmation_code: null,
            });
            ddLogger.info(`Error adding trip: ${e?.message}. ReservationId: ${id}, UserId: ${user.id}`, {
                reservation_id: id,
                user_id: user.id,
            });
            dispatch(setAddTripLoading(false));
            if (e.status === 401 || e.status === 403) {
                dispatch(setAddTripError(e));
            } else {
                dispatch(setIsShowingFindMyTripModal(false));
                dispatch(setFetchTripsError(e));
            }
        }
    };
}

export function setAddTripLoading(isLoading: boolean) {
    return {
        type: TripsActions.SET_ADD_TRIP_LOADING,
        payload: isLoading,
    };
}

export function setAddTripError(error: Error | null) {
    return {
        type: TripsActions.SET_ADD_TRIP_ERROR,
        payload: error,
    };
}

export function updateUpcomingTrips() {
    return {
        type: TripsActions.UPDATE_UPCOMING_TRIPS,
    };
}

export function removeCancelledReservation(reservationId: string) {
    return {
        type: TripsActions.REMOVE_CANCELLED_RESERVATION,
        payload: reservationId,
    };
}

export function refreshTrip(reservationId: string) {
    return async (dispatch: any) => {
        try {
            const accessToken = AuthUtils.getAccessToken();
            if (accessToken && !AuthUtils.isTokenExpired(accessToken)) {
                const trip = await tripService.getTripByReservationId(reservationId, accessToken);
                dispatch(updateTrip(trip));
                return;
            }
            ddLogger.warn(`WARNING no valid access token available to refresh trip: ${reservationId}`);
        } catch (e) {
            ddLogger.error(`ERROR trying to refresh trip ${reservationId}: ${e?.message}`);
        }
    };
}

import { Trip as TripType, TripIDVInProgress } from 'guest-data-types-ts-lib';

export enum TimeMap {
    PAST = 'PAST',
    CURRENT = 'CURRENT',
    UPCOMING = 'UPCOMING',
}

interface TripLite {
    type: string;
    attributes: { reservation_id: string };
}

export interface Trip {
    type: string;
    id: string;
    attributes: TripType | TripIDVInProgress;
}

export interface TripsLite extends Array<TripLite> {}

export interface MinifiedReservation {
    id: string;
    confirmation_code?: string | null;
}

export enum IdvStatus {
    PASSED = 'PASSED',
    FAILED = 'FAILED',
    IN_PROGRESS = 'IN_PROGRESS',
}

export interface CachedTrip extends Trip {
    meta: {
        hidden: boolean;
        cache_date: Date;
    };
}

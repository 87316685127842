import * as _ from 'lodash';
import { TripActionTypes, TripsActions, TripsState } from '../../types/trips.store.type';
import { TimeMap } from '../../types/trip.type';

const initialState: TripsState = {
    isFetchTripsLoading: false,
    fetchTripsError: null,
    currentTrips: [],
    upcomingTrips: [],
    pastTrips: [],
    isShowingFindMyTripsModal: false,
    isAddTripLoading: false,
    addTripError: null,
    isLoaded: false,
};

export function tripsReducer(state = initialState, action: TripActionTypes): TripsState {
    switch (action.type) {
        case TripsActions.SET_FETCH_TRIPS_LOADING: {
            return { ...state, isFetchTripsLoading: action.payload };
        }

        case TripsActions.SET_TRIP: {
            const { type, trip } = action.payload;

            if (type === TimeMap.CURRENT) {
                return {
                    ...state,
                    currentTrips: _.uniqBy([...state.currentTrips, trip], 'id'),
                };
            }

            if (type === TimeMap.UPCOMING) {
                return {
                    ...state,
                    upcomingTrips: _.uniqBy([...state.upcomingTrips, trip], 'id'),
                };
            }

            if (type === TimeMap.PAST) {
                return {
                    ...state,
                    pastTrips: _.uniqBy([...state.pastTrips, trip], 'id'),
                };
            }

            return state;
        }

        case TripsActions.UPDATE_TRIP: {
            const { type, trip } = action.payload;

            if (type === TimeMap.CURRENT) {
                const currentTrips = state.currentTrips;
                const idx = _.findIndex(currentTrips, (current) => trip.id === current.id);

                if (idx > -1) {
                    currentTrips.splice(idx, 1, trip);
                }

                return {
                    ...state,
                    currentTrips,
                };
            }

            if (type === TimeMap.UPCOMING) {
                const upcomingTrips = state.upcomingTrips;
                const idx = _.findIndex(upcomingTrips, (upcoming) => trip.id === upcoming.id);

                if (idx > -1) {
                    upcomingTrips.splice(idx, 1, trip);
                }
                return {
                    ...state,
                    upcomingTrips,
                };
            }

            if (type === TimeMap.PAST) {
                const pastTrips = state.pastTrips;
                const idx = _.findIndex(pastTrips, (past) => trip.id === past.id);

                if (idx > -1) {
                    pastTrips.splice(idx, 1, trip);
                }

                return {
                    ...state,
                    pastTrips,
                };
            }

            return state;
        }

        case TripsActions.SET_TRIPS_LOADED: {
            return { ...state, isLoaded: action.payload };
        }

        case TripsActions.SET_FETCH_TRIPS_ERROR: {
            return { ...state, fetchTripsError: action.payload };
        }

        case TripsActions.SET_ADD_TRIP_LOADING: {
            return { ...state, isAddTripLoading: action.payload };
        }

        case TripsActions.SET_ADD_TRIP_ERROR: {
            return { ...state, addTripError: action.payload };
        }

        case TripsActions.SET_IS_SHOWING_FIND_MY_TRIP_MODAL: {
            return { ...state, isShowingFindMyTripsModal: action.payload };
        }

        case TripsActions.UPDATE_UPCOMING_TRIPS: {
            if (state.currentTrips.length > 0 || state.upcomingTrips.length === 0) {
                return state;
            }

            const nextTrip = state.upcomingTrips[0];
            const upcomingTrips = state.upcomingTrips.slice(1);
            return { ...state, upcomingTrips, currentTrips: [nextTrip] };
        }

        case TripsActions.REMOVE_CANCELLED_RESERVATION: {
            const reservationId = action.payload;
            const { currentTrips, upcomingTrips, pastTrips } = state;

            _.remove(currentTrips, (trip) => trip.id === reservationId);
            _.remove(upcomingTrips, (trip) => trip.id === reservationId);
            _.remove(pastTrips, (trip) => trip.id === reservationId);

            return { ...state, currentTrips, upcomingTrips, pastTrips };
        }

        default: {
            return state;
        }
    }
}

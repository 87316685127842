export class ValidationUtils {
    public static isEmptyOrWhiteSpace(value: string): boolean {
        const regex = new RegExp(/^\s*$/);
        const isWhiteSpace = regex.test(value);
        const isEmpty = value === '';
        return isEmpty || isWhiteSpace;
    }

    public static validateAndGetEnvVar(variable: string): string {
        const value = process.env[variable];
        if (!value || ValidationUtils.isEmptyOrWhiteSpace(value)) {
            const message = `Variable ${variable} is undefined, empty or white space`;
            throw new Error(message);
        }

        return value;
    }

    public static getDomain(): string {
        const environment = process.env.REACT_APP_CUSTOM_NODE_ENV;
        switch (environment) {
            case 'production': {
                return 'vacasa.com';
            }

            case 'local': {
                return 'localhost';
            }

            default: {
                return 'vacasastage.com';
            }
        }
    }

    public static getCookieDomain() {
        const domain = this.getDomain();
        if (domain === 'localhost') {
            return '.app.localhost';
        }
        return `.${domain}`;
    }
}

import * as crypto from 'crypto';
import { gzip, ungzip } from 'node-gzip';
import { CookieKeys, StorageKeys } from '../types/keys.type';
import Cookies, { CookieAttributes } from 'js-cookie';
import { Trip } from '../types/trip.type';
import ddLogger from '../logging/ddLogger';
import { MEMORY_STORAGE } from '../App';
import { Trip as TripType } from 'guest-data-types-ts-lib/types/trip';
import { TripIDVInProgress } from 'guest-data-types-ts-lib/index';

export class SharedUtils {
    public static setCookie(key: CookieKeys, value: string | object, options?: CookieAttributes): void {
        Cookies.set(key, value, options);
    }

    public static getCookie(key: CookieKeys): string | undefined {
        return Cookies.get(key);
    }

    public static setSessionStorageItem(key: string, item: any): void {
        try {
            MEMORY_STORAGE[key] = item;
            sessionStorage.setItem(key, item);
        } catch (e) {
            ddLogger.error(`ERROR setting ${key} sessionStorageItem: ${e?.message}`);
        }
    }

    public static getSessionStorageItem(key: string): string | null {
        return sessionStorage.getItem(key) || MEMORY_STORAGE[key] || null;
    }

    public static removeLocalStorageItem(key: string): void {
        sessionStorage.removeItem(key);
        MEMORY_STORAGE[key] = undefined;
    }

    public static getLocalStorageItem(key: string): string | null {
        return localStorage.getItem(key);
    }

    public static setLocalStorageItem(key: string, item: string): void {
        localStorage.setItem(key, item);
    }

    public static encrypt(value: string): string {
        return crypto
            .createHash('sha256')
            .update(value)
            .digest('base64');
    }

    public static async compress<T>(data: T): Promise<string> {
        return (await gzip(JSON.stringify(data))).toString('base64');
    }

    public static async uncompress<T>(data: string): Promise<T> {
        try {
            const buffer = Buffer.from(data, 'base64');
            const deflated = (await ungzip(buffer)).toString();
            return JSON.parse(deflated);
        } catch (e) {
            ddLogger.error(`ERROR uncompressing data: ${e?.message}`);
            return null as any;
        }
    }

    public static generateKey(prefix: CookieKeys | StorageKeys, key: string): string {
        const encryptedKey = SharedUtils.encrypt(key);
        return `${prefix}_${encryptedKey}`;
    }

    public static async saveTripInCache(key: string, trip: Trip, hidden: boolean): Promise<void> {
        try {
            if (this.isIDVInProgressTrip(trip.attributes)) {
                return;
            }

            const minified: Trip & { meta: { hidden: boolean; cache_date: Date } } = {
                ...trip,
                meta: { hidden, cache_date: new Date() },
            };

            const compressedCache = await SharedUtils.compress(minified);
            SharedUtils.setLocalStorageItem(key, compressedCache);
        } catch (e) {
            ddLogger.error(`ERROR saving trip ${trip.id} to cache: ${e?.message}`);
        }
    }

    public static isIDVInProgressTrip = (attributes: TripType | TripIDVInProgress): attributes is TripIDVInProgress => {
        return 'unit_overview' in attributes;
    };
}

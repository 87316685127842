import { AppStrings } from '../messages';

export const English: AppStrings = {
    language_and_currency: 'Language',

    property_management: 'Vacation Rental Management',
    real_estate: 'Real Estate',
    company: 'Company',
    innovation: 'Innovation',
    community: 'Community',
    about_us: 'About Us',
    careers: 'Careers',
    newsroom: 'Newsroom',
    investors: 'Investors',
    blog: 'Blog',
    solutions_partnerships: 'Solutions and Partnerships',
    accessibility: 'Accessibility',
    leadership: 'Leadership',
    social_responsibility: 'Social Responsibility',

    help: 'Help',
    support_contact: 'Support and Contact',
    toll_free: 'Toll Free:',
    local: 'Local:',
    covid_update: 'COVID-19 Updates',
    cancel_my_trip: 'Cancel My Trip',

    my_account: 'Log In / Sign Up',
    hi: 'Hi, ',
    profile: 'Profile',
    favorites: 'Favorites',
    login: 'Guest Login / Signup',
    logout: 'Log Out',
    find_my_trip: 'Find My Trip',
    find_your_trip: 'Find your trip',
    my_trips: 'My Trips',
    owner_login: 'Homeowner Account',

    sitemap: 'Sitemap',
    privacy: 'Privacy',
    terms_of_service: 'Terms of Service',

    your_vacation_full_time_job: 'Your vacation is our full-time job',
    your_vacation_full_time_job_note:
        'When you book with Vacasa, you’re getting more than a vacation rental. You’re getting professional service you can count on.',

    not_trip_found: 'No trips found',
    not_reservations_found_for: 'No reservations found for',
    book_a_trip: 'Book a Trip',

    confirmation: 'Confirmation',
    last_name: 'Last name',
    find_trip_note: 'Enter the code from your confirmation email and the last name associated with your trip.',
    add_trip: 'Add Trip',
    not_correct_trip_info_note: "That trip information isn't quite right. Please check and try again.",
    dont_see_your_trip: "Don't see your trip?",
    dont_see_your_trip_note: 'Add it using the code from your confirmation email.',
    past_trips: 'Past Trips',
    current_trip: 'Current Trip',
    need_help: 'Need help?',

    early_checkout_available: 'Early check-in is available!',
    add_review: 'Add your review',
    book_again: 'Book again',
    late_checkout: 'Late check-out is available!',
    late_checkout_purchased: 'You have purchased late check-out.',

    find_my_trip_note: 'Enter the code from your confirmation email and the last name associated with your trip.',
    info_is_not_right: "That information isn't quite right.",
    check_and_try_again: 'Please check and try again.',

    item_needs_attention: 'Item needs attention',
    items_need_attention: 'Items need attention',

    somethings_not_right: 'Something is not right',
    try_later_or_call: 'Please try later or call your Vacasa representative at',

    or: 'or',

    confirmation_code: 'Confirmation code',
    need_help_note:
        "You'll find the code for your trip in the confirmation email from Vacasa, alongside the name and address of the vacation home. It's usually a combination of 8 letters and numbers.",
    need_help_note_third_party:
        'Please note that you must enter the code from Vacasa, as we cannot process third-party codes at this time.',
    okay: 'Okay',

    download_app_mobile_note: 'Trip details, check-in info, & more',
    download_app_mobile_title: 'Get it all in the app',

    toll_free_phone_number: '+1 800-544-0300',

    browser_deprecated: 'Browser not supported',
    please_upgrade_browser: 'Please upgrade or use another browser to view this site',

    info_panel: 'Information Panel',
    close_info_panel: 'Close Information Panel',

    open: 'Open',

    star_rating_title: 'Review your stay',
    star_rating_note: 'How was your experience at {unitName}? Your feedback is important to us.',
    next: 'Next',
};

export class Configuration {
    public static ignoreErrorList: string[] = [
        'RESERVATION_CANCELLED',
        'LEGACY_TOKEN_NOT_FOUND',
        'LEGACY_TOKEN_ERROR',
        'Network Error',
        'timeout of 0ms exceeded',
        'RESERVATION_IN_BLOCKLISTS',
        'IDV_FAILED',
        'STATUS 403',
        'STATUS 401',
        'STATUS 500',
        'docs-homescreen-gb-container',
        'DOMNodeInsertedFunc',
        'QuotaExceededError',
        'operation was aborted',
        'chrome-extension:',
        "Unexpected token '<'",
        'Cannot redefine property: play',
        "cannot be created in a document with origin 'https://translate.googleusercontent.com'",
        'Extension context invalidated',
        'Request aborted',
        "evaluating 'window.webkit.messageHandlers.adblock",
        `null is not an object (evaluating 'e.getResponseHeader("content-type").indexOf')`,
        'leadflows.js',
        'ceCurrentVideo',
        'safari-extension:',
        'safari-web-extension',
    ];
}

import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { TranslationUtils, ValidationUtils } from '../../utils';
import { Button } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../types/store.types';
import { addTrip, setAddTripError, setIsShowingFindMyTripModal } from '../../store/actions';

import './FindMyTripModal.scss';
import { UserState } from '../../types/user.store.type';
import { IconAlertOctagon } from '../../assets/icons';

export const FIND_MY_TRIP_MODAL = 'FIND_MY_TRIP_MODAL';

export const FindMyTripModal: React.FC = () => {
    const dispatch = useDispatch();

    const storeUser: UserState = useSelector((state: StoreState) => state.user);
    const { isShowingFindMyTripsModal, isAddTripLoading, addTripError } = useSelector((state: StoreState) => state.trips);

    const [tripId, setTripId] = useState('');
    const [lastName, setLastName] = useState('');

    const { user } = storeUser;

    if (!user) {
        return null;
    }

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTripId(e.target.value || '');
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };

    const isFormInvalid = (lastName: string, confirmation: string): boolean => {
        return isAddTripLoading || ValidationUtils.isEmptyOrWhiteSpace(confirmation) || ValidationUtils.isEmptyOrWhiteSpace(lastName);
    };

    const isSubmitDisabled = isFormInvalid(lastName, tripId);

    return (
        <Modal
            show={isShowingFindMyTripsModal}
            onHide={() => {
                dispatch(setIsShowingFindMyTripModal(false));
            }}
            onExited={() => {
                setLastName('');
                setTripId('');
                dispatch(setAddTripError(null));
            }}>
            <Modal.Header closeButton />

            <Modal.Body>
                <div className="pl-5 pr-5 pb-5">
                    <h1 tabIndex={0} className="type-heading-large heading-xxs">
                        {TranslationUtils.get('find_your_trip')}
                    </h1>

                    {addTripError && (
                        <div className="find-my-trip-modal-error body-regular">
                            <IconAlertOctagon className="find-my-trip-modal-error-icon" />
                            <span tabIndex={0} className="find-my-trip-modal-error-message">
                                {TranslationUtils.get('info_is_not_right')}
                                <br /> {TranslationUtils.get('check_and_try_again')}
                            </span>
                        </div>
                    )}

                    <p tabIndex={0} className={'body-regular'}>{TranslationUtils.get('find_trip_note')}</p>

                    <Form>
                        <Form.Group>
                            <Form.Label tabIndex={0} className={'body-regular'}>{TranslationUtils.get('confirmation')}</Form.Label>
                            <Form.Control
                                id="find-my-trips-confirmation-input"
                                className={'body-regular'}
                                type="text"
                                placeholder={`ex: 123456 ${TranslationUtils.get('or')} 3V68-9MRY`}
                                value={tripId}
                                onChange={handleIdChange}
                            />
                            <br />
                            <Form.Label tabIndex={0} className={'body-regular'}>{TranslationUtils.get('last_name')}</Form.Label>
                            <Form.Control
                                id="find-my-trips-last-name-input"
                                className={'body-regular'}
                                type="text"
                                placeholder="ex: Smith"
                                value={lastName}
                                onChange={handleLastNameChange}
                            />
                        </Form.Group>
                        <Button
                            id="button-add-trip"
                            block
                            variant="primary"
                            className="find-my-trip-modal-button"
                            onClick={() => {
                                dispatch(addTrip(tripId, lastName, user));
                            }}
                            label={TranslationUtils.get('add_trip')}
                            disabled={isSubmitDisabled}
                            isLoading={isAddTripLoading}
                        />
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

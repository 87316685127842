import React, { useRef } from 'react';
import $ from 'jquery';
import { IconCaretDownNoRadius, IconGlobeFilled, IconHeadSet } from '../../../assets/icons';
import languages from '../../../assets/languages.json';
import { UserState } from '../../../types/user.store.type';
import { TranslationUtils, ValidationUtils } from '../../../utils';
import * as _ from 'lodash';

interface RightNavMenuProps {
    user: UserState;
    onLanguageChange: (languageCode: string) => void;
    onCurrencyChange: (e: any) => void;
    onLogin: () => void;
    onLogout: () => void;
}

interface LinkReference {
    currency: any;
    language: any;
    help: any;
    navbar: any;
}

export const RightNavBarMenu: React.FC<RightNavMenuProps> = (props) => {
    const vacasaUrl = ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL');
    const localPhoneNumber = ValidationUtils.validateAndGetEnvVar('REACT_APP_LOCAL_PHONE_NUMBER');
    const { user, onLogin, onLogout, onLanguageChange } = props;

    const linkReferences = {
        currency: useRef<any>(),
        language: useRef<any>(),
        help: useRef<any>(),
        navbar: useRef<any>(),
    };

    const isEnterOrSpaceKey = (key: number) => {
        return key === 13 || key === 32;
    };

    const handleLinkKeyDown = (e: any, name: keyof LinkReference) => {
        if (isEnterOrSpaceKey(e.keyCode)) {
            linkReferences[name].current.click();
        }
    };

    const handleOptionKeyDown = (e: any, callBack: (p?: any) => void, parameter?: any) => {
        if (isEnterOrSpaceKey(e.keyCode)) {
            e.preventDefault();
            callBack(parameter);
        }
    };

    const renderLink = (id: string, className: string, path: string, displayName: string) => {
        return (
            <a tabIndex={0} id={id} className={className} rel="noopener noreferrer" href={path}>
                <span>{displayName}</span>
            </a>
        );
    };

    const caretOffset = 21;
    $('#language-link-mobile').width($('#language_hidden_select').width()! - caretOffset);
    $('#currency-link-mobile').width($('#currency_hidden_select').width()! - caretOffset);

    return (
        <div className="navbar-nav ml-auto fix-right-nav">
            <div
                tabIndex={0}
                className="nav-item-v2 nav-item dropdown d-flex mr-0 ml-0"
                onKeyDown={(e) => handleLinkKeyDown(e, 'language')}>
                <a
                    id="language-link"
                    className="nav-link-v2 dropdown-toggle d-flex mr-0"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    ref={linkReferences.language}>
                    <span>
                        <IconGlobeFilled className="feather globe" />
                    </span>
                    <IconCaretDownNoRadius className="icon-caret-down-nav-v2" />
                </a>
                <div id="language-picker" className="dropdown-menu dropdown-menu-left mr-0 fix-language" aria-labelledby="language-link">
                    {_.map(languages, (language, index) => {
                        return (
                            <a
                                tabIndex={0}
                                id={`option-${language.code}`}
                                className="dropdown-item nav-font"
                                key={index}
                                onClick={() => onLanguageChange(language.code)}
                                onKeyDown={(e) => handleOptionKeyDown(e, onLanguageChange, language.code)}>
                                {language.name}
                            </a>
                        );
                    })}
                </div>
            </div>
            <div tabIndex={0} className="nav-item-v2 nav-item dropdown d-flex mr-0" onKeyDown={(e) => handleLinkKeyDown(e, 'help')}>
                <a
                    id="help-link"
                    className="nav-link-v2 dropdown-toggle d-flex mr-0"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    ref={linkReferences.help}>
                    <span>
                        <IconHeadSet className="mb-1 icon-head-set" />
                    </span>
                    <IconCaretDownNoRadius className="icon-caret-down-nav-v2" />
                </a>
                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="help-link">
                    <a
                        id="navbar-support"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${vacasaUrl}/support`}
                        className="dropdown-item nav-font">
                        {TranslationUtils.get('support_contact')}
                    </a>
                    <a
                        tabIndex={0}
                        id="navbar-tollfree-phone"
                        className="dropdown-item nav-font"
                        href={`tel:${TranslationUtils.get('toll_free_phone_number')}`}>
                        {`${TranslationUtils.get('toll_free')} ${TranslationUtils.get('toll_free_phone_number')}`}
                    </a>
                    <a tabIndex={0} id="navbar-local-phone" className="dropdown-item nav-font" href={`tel:${localPhoneNumber}`}>
                        {`${TranslationUtils.get('local')} ${localPhoneNumber}`}
                    </a>
                </div>
            </div>
            {user.user ? (
                <div
                    tabIndex={0}
                    className="nav-item-v2 nav-item dropdown d-flex mr-0 nav-font"
                    onKeyDown={(e) => handleLinkKeyDown(e, 'navbar')}>
                    <a
                        id="navbar-my-vacasa"
                        className="nav-link-v2 dropdown-toggle d-flex mr-0"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ref={linkReferences.navbar}>
                        <span id="user-greeting" className={'account-name'}>
                            {TranslationUtils.get('hi')}
                            {user.user.name}
                        </span>
                        <IconCaretDownNoRadius className="icon-caret-down-nav-v2" />
                    </a>
                    <div className="my-vacasa-menu dropdown-menu dropdown-menu-left" aria-labelledby="navbar-my-vacasa">
                        {renderLink('link-my-trips-dropdown', 'dropdown-item', `${vacasaUrl}/my-trips`, TranslationUtils.get('my_trips'))}
                        <div className="dropdown-divider" />
                        {renderLink(
                            'link-favorites-dropdown',
                            'dropdown-item',
                            `${vacasaUrl}/favorites`,
                            TranslationUtils.get('favorites')
                        )}
                        {renderLink('link-profile-dropdown', 'dropdown-item', `${vacasaUrl}/my-profile`, TranslationUtils.get('profile'))}
                        <div className="dropdown-divider" />
                        {renderLink(
                            'navbar-owner-login',
                            'dropdown-item',
                            'https://owners.vacasa.com/login',
                            TranslationUtils.get('owner_login')
                        )}
                        <div className="dropdown-divider" />
                        <a tabIndex={0} className="dropdown-item" onClick={onLogout}>
                            <span>{TranslationUtils.get('logout')}</span>
                        </a>
                    </div>
                </div>
            ) : (
                <div
                    tabIndex={0}
                    className="nav-item-v2 nav-item dropdown d-flex nav-font mr-0"
                    onKeyDown={(e) => handleLinkKeyDown(e, 'navbar')}>
                    <a
                        id="navbar-my-vacasa"
                        className="nav-link-v2 dropdown-toggle d-flex mr-0"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ref={linkReferences.navbar}>
                        <span>{TranslationUtils.get('my_account')}</span>
                        <IconCaretDownNoRadius className="icon-caret-down-nav-v2" />
                    </a>
                    <div className="my-vacasa-menu dropdown-menu dropdown-menu-left" aria-labelledby="navbar-my-vacasa">
                        {renderLink(
                            'navbar-owner-login',
                            'dropdown-item',
                            'https://owners.vacasa.com/login',
                            TranslationUtils.get('owner_login')
                        )}
                        <div className="dropdown-divider" />
                        <a
                            tabIndex={0}
                            id="navbar-login"
                            className="dropdown-item"
                            onClick={onLogin}
                            onKeyDown={(e) => handleOptionKeyDown(e, onLogin)}>
                            <span>{TranslationUtils.get('login')}</span>
                        </a>
                        <div className="dropdown-divider" />
                        {renderLink('navbar-trip-login', 'dropdown-item', `${vacasaUrl}/my-trips`, TranslationUtils.get('find_my_trip'))}
                    </div>
                </div>
            )}
        </div>
    );
};

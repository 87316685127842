import { AppStrings } from '../messages';

export const Portuguese: AppStrings = {
    language_and_currency: 'Idioma',

    property_management: 'Gestão de Aluguel de Férias',
    real_estate: 'Imobiliária',
    company: 'Companhia',
    community: 'Comunidade',
    innovation: 'Inovação',
    about_us: 'Sobre nós',
    careers: 'Carreiras',
    newsroom: 'Redação',
    investors: 'Investidores',
    blog: 'Blog',
    solutions_partnerships: 'Soluções e Parcerias',
    accessibility: 'Acessibilidade',
    leadership: 'Liderança',
    social_responsibility: 'Responsabilidade social',

    help: 'Ajude-Me!',
    support_contact: 'Suporte e Contato',
    toll_free: 'Linha grátis:',
    local: 'Local:',
    covid_update: 'COVID-19 Updates',
    cancel_my_trip: 'Cancelar Minha Viagem',

    my_account: 'Entrar / Registrar-se',
    hi: 'Oi, ',
    profile: 'Perfil',
    favorites: 'Favoritos',
    login: 'Login de Hóspedes / Cadastro',
    logout: 'Sair',
    find_my_trip: 'Encontrar minha viagem',
    find_your_trip: 'Encontre sua viagem',
    my_trips: 'Minhas viagens',
    owner_login: 'Conta de Proprietário',

    sitemap: 'Mapa do site',
    privacy: 'Privacidade',
    terms_of_service: 'Termos de serviço',

    your_vacation_full_time_job: 'Estamos dedicados em tempo integral às suas férias.',
    your_vacation_full_time_job_note:
        'Ao fazer uma reserva com a Vacasa, você recebe muito mais do que um aluguel para férias. Recebe um serviço profissional e confiável.',

    not_trip_found: 'Não se encontraram viagens',
    not_reservations_found_for: 'Não se encontraram reservas para',
    book_a_trip: 'Reservar uma viagem',

    confirmation: 'Confirmação',
    last_name: 'Sobrenome',
    find_trip_note: 'Digite o código que aparece em seu correio eletrônico de confirmação e o sobrenome associado a sua viagem.',
    add_trip: 'Adicionar viagem',
    not_correct_trip_info_note: 'A informação de viagem não é correta. Verifique-a e tente novamente.',
    dont_see_your_trip: 'Não encontra sua viagem?',
    dont_see_your_trip_note: 'Adicione-a usando o código que aparece em seu correio eletrônico de confirmação.',
    past_trips: 'Viagens feitas',
    current_trip: 'Viagem atual',
    need_help: 'Você precisa de ajuda?',

    early_checkout_available: 'Early check-in está disponível!',
    add_review: 'Adicione seu comentário',
    book_again: 'Reservar novamente',
    late_checkout: 'Late check-out está disponível!',
    late_checkout_purchased: 'Você comprou late check-out.',

    find_my_trip_note: 'Digite o código que aparece em seu correio eletrônico de confirmação e o sobrenome associado a sua viagem.',
    info_is_not_right: 'A informação não é correta.',
    check_and_try_again: 'Verifique-a e tente novamente.',

    item_needs_attention: 'Item requer atenção',
    items_need_attention: 'Itens requerem atenção',

    somethings_not_right: 'Houve algum problema',
    try_later_or_call: 'Tente novamente mais tarde ou ligue para seu representante da Vacasa em',

    or: 'ou',

    confirmation_code: 'Código de confirmação',
    need_help_note:
        'Você encontrará o código da sua viagem no e-mail de confirmação da Vacasa, junto com o nome e o endereço da casa de férias. Geralmente é uma combinação de 8 letras e números.',
    need_help_note_third_party:
        'Lembre-se de que você deve digitar o código da Vacasa, pois não podemos processar códigos de terceiros neste momento.',
    okay: 'Ok',

    download_app_mobile_note: 'Faça o check-in de forma fácil com a aplicação da Vacasa!',
    download_app_mobile_title: 'Transferir aplicação',

    toll_free_phone_number: '+1 844-259-7030',

    browser_deprecated: 'Navegador não suportado',
    please_upgrade_browser: 'Por favor, atualize ou use outro navegador para visualizar este site',

    info_panel: 'Painel de Informações',
    close_info_panel: 'Fechar painel de informações',

    open: 'Open',

    star_rating_title: 'Reveja a sua estadia',
    star_rating_note: 'Como foi sua experiência em {unitName}? A sua opinião é importante para nós.',
    next: 'Próximo',
};

export class Asynchronizer {
    private promises: Array<Promise<any>> = [];
    private threshold: number = 3;

    public async add(promise: Promise<any>): Promise<null | any[]> {
        this.promises.push(
            promise.catch((e) => {
                console.error('ERROR executing promise:', e?.message);
            })
        );

        if (this.promises.length >= this.threshold) {
            const result = await Promise.all(this.promises);
            this.promises = [];
            return result;
        }

        return null;
    }

    public async waitForRemainingPromises(): Promise<any[]> {
        return await Promise.all(this.promises);
    }
}

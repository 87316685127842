import { createStore, combineReducers, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { tripsReducer, userReducer } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { UserState } from '../types/user.store.type';
import { TripsState } from '../types/trips.store.type';

const rootReducer = combineReducers({ user: userReducer, trips: tripsReducer });
const isProduction = process.env.REACT_APP_CUSTOM_NODE_ENV && process.env.REACT_APP_CUSTOM_NODE_ENV === 'production';

let store: Store<{ trips: TripsState; user: UserState }>;

if (isProduction) {
    store = createStore(rootReducer, applyMiddleware(thunk));
} else {
    store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
}

export default store;

import ddLogger from '../../logging/ddLogger';
import { AuthUtils } from '../../utils';

class SegmentService {
    public trackEvent(title: string, data?: { [key: string]: any }) {
        try {
            const identityToken = AuthUtils.getIdentityToken();
            const user_id = identityToken?.sub || undefined;
            const idp_email = identityToken?.email || undefined;

            if ((window as any).analytics) {
                const options = {
                    category: 'guest',
                    page_version: 'my_trips',
                    user_id,
                    idp_email,
                    ...data,
                };

                (window as any).analytics.track(title, options);
            }
        } catch (e) {
            ddLogger.error('Segment trackEvent Error', e);
        }
    }

    public trackPageView() {
        try {
            if ((window as any).analytics) {
                (window as any).analytics.page('My Trips Viewed');
            }
        } catch (e) {
            ddLogger.error('Segment trackPageView Error', e);
        }
    }
}

export default new SegmentService();

import { English, SpanishLatam, SpanishSpanish, French, German, Portuguese, Italian } from './languages';

export interface AppStrings {
    language_and_currency: string;

    property_management: string;
    real_estate: string;
    company: string;
    innovation: string;
    community: string;
    about_us: string;
    careers: string;
    newsroom: string;
    investors: string;
    blog: string;
    solutions_partnerships: string;
    accessibility: string;
    leadership: string;
    social_responsibility: string;

    help: string;
    support_contact: string;
    toll_free: string;
    local: string;
    covid_update: string;
    cancel_my_trip: string;

    my_account: string;
    hi: string;
    profile: string;
    favorites: string;
    login: string;
    logout: string;
    find_my_trip: string;
    find_your_trip: string;
    my_trips: string;
    owner_login: string;

    sitemap: string;
    privacy: string;
    terms_of_service: string;

    your_vacation_full_time_job: string;
    your_vacation_full_time_job_note: string;

    not_trip_found: string;
    not_reservations_found_for: string;
    book_a_trip: string;

    confirmation: string;
    last_name: string;
    find_trip_note: string;
    add_trip: string;
    not_correct_trip_info_note: string;
    dont_see_your_trip: string;
    dont_see_your_trip_note: string;
    current_trip: string;
    past_trips: string;
    need_help: string;

    early_checkout_available: string;
    add_review: string;
    book_again: string;
    late_checkout: string;
    late_checkout_purchased: string;

    find_my_trip_note: string;
    info_is_not_right: string;
    check_and_try_again: string;

    item_needs_attention: string;
    items_need_attention: string;

    somethings_not_right: string;
    try_later_or_call: string;

    or: string;

    confirmation_code: string;
    need_help_note: string;
    need_help_note_third_party: string;
    okay: string;

    download_app_mobile_note: string;
    download_app_mobile_title: string;

    toll_free_phone_number: string;

    browser_deprecated: string;
    please_upgrade_browser: string;

    info_panel: string;
    close_info_panel: string;

    open: string;

    star_rating_title: string;
    star_rating_note: string;
    next: string;
}

export const messages: { [lang: string]: AppStrings } = {
    en: English,
    es: SpanishLatam,
    'es-419': SpanishLatam,
    'es-ES': SpanishSpanish,
    fr: French,
    de: German,
    pt: Portuguese,
    it: Italian,
};

import { AppStrings } from '../messages';

export const SpanishSpanish: AppStrings = {
    language_and_currency: 'Idioma',

    property_management: 'Gestión de Alquileres Vacacionales',
    real_estate: 'Bienes raíces',
    company: 'Empresa',
    innovation: 'Innovación',
    community: 'Comunidad',
    about_us: 'Sobre Nosotros',
    careers: 'Carreras',
    newsroom: 'Sala de Prensa',
    investors: 'Inversores',
    blog: 'Blog',
    solutions_partnerships: 'Soluciones y Alianzas',
    accessibility: 'Accesibilidad',
    leadership: 'Liderazgo',
    social_responsibility: 'Responsabilidad Social',

    help: 'Ayuda',
    support_contact: 'Soporte y Contacto',
    toll_free: 'Tel. Gratuito:',
    local: 'Local:',
    covid_update: 'Actualizaciones de COVID-19',
    cancel_my_trip: 'Cancelar Mi Viaje',

    my_account: 'Iniciar Sesión / Registrarse',
    hi: 'Hola, ',
    profile: 'Perfil',
    favorites: 'Favoritos',
    login: 'Ingreso / Registro de Huésped',
    logout: 'Cerrar Sesión',
    find_my_trip: 'Encontrar mi viaje',
    find_your_trip: 'Encuentra tu viaje',
    my_trips: 'Mis viajes',
    owner_login: 'Cuenta de Propietario',

    sitemap: 'Mapa del Sitio',
    privacy: 'Privacidad',
    terms_of_service: 'Terminos de Servicio',

    your_vacation_full_time_job: 'Nos dedicamos a tus vacaciones por completo',
    your_vacation_full_time_job_note:
        'Si reservas con Vacasa, obtienes más que un alquiler vacacional. Obtienes un servicio profesional con el que puedes contar.',

    not_trip_found: 'No se han encontrado viajes.',
    not_reservations_found_for: 'No se han encontrado reservas para',
    book_a_trip: 'Reservar un viaje',

    confirmation: 'Confirmación',
    last_name: 'Apellido',
    find_trip_note: 'Introduce el código que encontrarás en el correo electrónico de confirmación y el apellido asociado a tu viaje.',
    add_trip: 'Añadir viaje',
    not_correct_trip_info_note: 'Ese dato sobre el viaje no es correcto. Compruébalo y vuelve a intentarlo.',
    dont_see_your_trip: '¿No ves tu viaje?',
    dont_see_your_trip_note: 'Añádelo utilizando el código que encontrarás en el correo electrónico de confirmación.',
    past_trips: 'Viajes anteriores',
    current_trip: 'Viaje actual',
    need_help: '¿Necesitas ayuda?',

    early_checkout_available: '¡Early check-in está disponible!',
    add_review: 'Añadir tu comentario',
    book_again: 'Volver a reservar',
    late_checkout: '¡Late check-out disponible!',
    late_checkout_purchased: 'Has comprado late check-out.',

    find_my_trip_note: 'Introduce el código que encontrarás en el correo electrónico de confirmación y los apellidos asociados a tu viaje.',
    info_is_not_right: 'Ese dato no es correcto.',
    check_and_try_again: 'Compruébalo y vuelve a intentarlo.',

    item_needs_attention: 'Elemento requiere de tu atención',
    items_need_attention: 'Elementos requieren de tu atención',

    somethings_not_right: 'Algo no va bien',
    try_later_or_call: 'Inténtalo de nuevo más tarde o llama a tu representante de Vacasa al',

    or: 'ó',

    confirmation_code: 'Código de confirmación',
    need_help_note:
        'Encontrarás el código en el correo de confirmación del viaje de Vacasa, junto con el nombre y la dirección de la casa de vacaciones. Suele ser una combinación de 8 letras y números.',
    need_help_note_third_party:
        'Recuerda que debes introducir el código de Vacasa, ya que en estos momentos no podemos procesar códigos de terceros.',
    okay: 'De acuerdo',

    download_app_mobile_note: 'Detalles del viaje, información de check-in y más.',
    download_app_mobile_title: 'Consígalo todo en la app',

    toll_free_phone_number: '+1 844-697-2474',

    browser_deprecated: 'Navegador no soportado',
    please_upgrade_browser: 'Por favor, actualice o use otro navegador para ver este sitio',

    info_panel: 'Panel informativo',
    close_info_panel: 'Cerrar panel informativo',

    open: 'Abrir',

    star_rating_title: 'Revisa tu estadía',
    star_rating_note: '¿Cómo fue tu experiencia en {unitName}? Tu feedback es importante para nosotros.',
    next: 'Siguiente',
};

import React from 'react';
import { TranslationUtils } from '../../utils';
import './InfoPanel.scss';
import { ImageVacationFullTime } from '../../assets/images';

interface InfoPanelProps {
    show: boolean;
    handleClose: () => any;
}

export const InfoPanel: React.FC<InfoPanelProps> = (props) => {
    const { show, handleClose } = props;
    return (
        <div id={'info-panel-top'} className={show ? 'row' : 'hide-page'}>
            <div
                tabIndex={0}
                aria-label={`${TranslationUtils.get('info_panel')} ${TranslationUtils.get(
                    'your_vacation_full_time_job'
                )} ${TranslationUtils.get('your_vacation_full_time_job_note')}`}
                className={'col-md-10 col-lg-7 text-panel'}>
                <h4 className={'heading-xxs'}>{TranslationUtils.get('your_vacation_full_time_job')}</h4>
                <p>{TranslationUtils.get('your_vacation_full_time_job_note')}</p>
            </div>
            <div className={'col-3 d-none d-lg-block'}>
                <ImageVacationFullTime className={'image-panel'} />
            </div>
            <div className={'modal-header col-2 button-close-panel'}>
                <a
                    aria-label={TranslationUtils.get('close_info_panel')}
                    id={'link-close-info-panel'}
                    tabIndex={0}
                    role={'button'}
                    onKeyPress={() => {
                        handleClose();
                    }}
                    onClick={() => {
                        handleClose();
                    }}>
                    {'X'}
                </a>
            </div>
        </div>
    );
};

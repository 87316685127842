import * as _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoPanel, Loading, NoTripsFound, NoTripsFoundVariant, TripCardVariant, TripGrid } from '../../components';
import { fetchTrips, refreshTrip, setTripsLoaded } from '../../store/actions';
import { StoreState } from '../../types/store.types';
import { Trip } from '../../types/trip.type';
import { SharedUtils, TranslationUtils } from '../../utils';
import './Home.scss';
import { CookieKeys } from '../../types/keys.type';
import { Banner } from '../../components/Banner/Banner';
import { FeatureFlag } from '../../types/featureFlag.type';
import { usePageViewTracker } from '../../hooks';
import {useFeatureConfig} from "../../services/optimizely/optimizelyHelper";

export const Home: React.FC = () => {
    const { user: userStore } = useSelector((store: StoreState) => store);
    const { isFetchTripsLoading, currentTrips, upcomingTrips, pastTrips, isLoaded } = useSelector((state: StoreState) => state.trips);
    const { isEnabled: isAppBannerEnable } = useFeatureConfig(FeatureFlag.GUEST_PORTAL_MOBILE_APP, false);
    const [isShowingInfoModal, setIsShowingInfoModal] = useState(false);
    const [isShowingAppBanner, setIsShowingAppBanner] = useState(true);
    const dispatch = useDispatch();
    const [refreshList, setRefreshList] = useState<string[]>([]);
    usePageViewTracker();
    const refreshListRef = useRef(refreshList);

    useEffect(() => {
        document.addEventListener(
            'visibilitychange',
            () => {
                const list = refreshListRef.current;
                if (!document.hidden && list.length > 0) {
                    for (const reservationId of list) {
                        dispatch(refreshTrip(reservationId));
                    }
                    setRefreshList([]);
                }
            },
            false
        );
    }, []);

    useEffect(() => {
        if (userStore.user && userStore.user.accessToken && !isLoaded) {
            dispatch(fetchTrips(userStore.user));
            dispatch(setTripsLoaded(true));
        }
        handleInfoPanelShow();
    }, [userStore]);

    const addToRefreshList = (reservationId: string) => {
        const newList = _.uniq([...refreshList, reservationId]);
        setRefreshList(newList);
        refreshListRef.current = newList;
    };

    const handleInfoPanelShow = () => {
        if (userStore.user?.email) {
            const dismissKey = SharedUtils.generateKey(CookieKeys.INFO_MODAL_DISMISSED, userStore.user.email);
            const dismissedModalCookie = SharedUtils.getCookie(dismissKey as CookieKeys);
            if (!dismissedModalCookie) {
                setIsShowingInfoModal(true);
            }
        }
    };

    const handleInfoPanelDismiss = () => {
        setIsShowingInfoModal(false);
        if (userStore.user?.email) {
            const dismissKey = SharedUtils.generateKey(CookieKeys.INFO_MODAL_DISMISSED, userStore.user.email);
            SharedUtils.setCookie(dismissKey as CookieKeys, 'true');
        }
    };

    const handleAppBannerDismiss = () => {
        setIsShowingAppBanner(false);
    };

    if (isFetchTripsLoading) {
        return <Loading />;
    }

    const sortByCheckInDate = (trips: Trip[]): Trip[] => {
        return _.sortBy(trips, (t) => {
            if (t.attributes && t.attributes.stay && t.attributes.stay.check_in_date) {
                return t.attributes && t.attributes.stay && t.attributes.stay.check_in_date;
            }

            return null;
        });
    };

    return (
        <React.Fragment>
            {currentTrips.length > 0 || upcomingTrips.length > 0 || pastTrips.length > 0 ? (
                <React.Fragment>
                    {isAppBannerEnable || false ? <Banner show={isShowingAppBanner} handleClose={handleAppBannerDismiss} /> : <React.Fragment />}
                    <div className={'separator-banner-title'} />
                    <h1 tabIndex={0} className="main-title">
                        {TranslationUtils.get('my_trips')}
                    </h1>
                    <div className={'separator-info-panel'} />
                    <InfoPanel show={isShowingInfoModal} handleClose={handleInfoPanelDismiss} />
                    <div className={'separator-current-info-panel'} />
                    {currentTrips.length > 0 && (
                        <div id={'grid-current-trips'} className="mb-5">
                            <TripGrid
                                variant={TripCardVariant.CURRENT_TRIP}
                                title="Current Trips"
                                trips={sortByCheckInDate(currentTrips)}
                                addToRefreshList={addToRefreshList}
                            />
                        </div>
                    )}

                    {upcomingTrips.length > 0 && (
                        <div id={'grid-upcoming-trips'}>
                            <TripGrid
                                variant={TripCardVariant.UPCOMING_TRIP}
                                title="Upcoming Trips"
                                trips={sortByCheckInDate(upcomingTrips)}
                                addToRefreshList={addToRefreshList}
                            />
                        </div>
                    )}
                    <div className="separator-no-trips-found" />
                    <div>
                        <NoTripsFound variant={NoTripsFoundVariant.ADD_TRIP} />
                    </div>
                    <div className={'separator-current-info-panel'} />
                    {pastTrips.length > 0 && (
                        <div id={'grid-past-trips'} className="mb-5" tabIndex={0} aria-label={TranslationUtils.get('past_trips')}>
                            <TripGrid
                                variant={TripCardVariant.PAST_TRIP}
                                title={TranslationUtils.get('past_trips')}
                                trips={sortByCheckInDate(pastTrips).reverse()}
                                addToRefreshList={addToRefreshList}
                            />
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className={'panel-no-trips-founds'}>
                        <NoTripsFound variant={NoTripsFoundVariant.NO_TRIPS} />
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

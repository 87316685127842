import { User, UserActions } from '../../types/user.store.type';

import { Language } from '../../types/preference.type';

export function logIn(user: User) {
    return {
        type: UserActions.LOGIN,
        payload: user,
    };
}

export function logout() {
    return {
        type: UserActions.LOGOUT,
    };
}

export function sessionExpired(isSessionExpired: boolean) {
    return {
        type: UserActions.SESSION_EXPIRED,
        payload: isSessionExpired,
    };
}

export function setCurrency(currency: string) {
    return {
        type: UserActions.SET_CURRENCY,
        payload: currency,
    };
}

export function setLanguage(language: Language) {
    return {
        type: UserActions.SET_LANGUAGE,
        payload: language,
    };
}

export function setHidePage(showPage: boolean) {
    return {
        type: UserActions.SET_HIDE_PAGE,
        payload: showPage,
    };
}

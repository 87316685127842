import { AppStrings } from '../messages';

export const French: AppStrings = {
    language_and_currency: 'Langue',

    property_management: 'Gestion des Locations Saisonnières',
    real_estate: 'Immobilier',
    company: 'Société',

    community: 'Communauté',
    innovation: 'Innovation',
    about_us: 'Qui Sommes-nous',
    careers: 'Emplois',
    newsroom: 'Salle de Presse',
    investors: 'Investisseurs',
    blog: 'Blog',
    solutions_partnerships: 'Solutions et Partenariats',
    accessibility: 'Accessibilité',
    leadership: 'Direction',
    social_responsibility: 'Responsabilité Sociale',

    help: 'Aide',
    support_contact: 'Assistance et Contact',
    toll_free: 'Numéro Gratuit:',
    local: 'Local:',
    covid_update: 'Mises à jour COVID-19',
    cancel_my_trip: 'Annuler Mon Voyage',

    my_account: 'Inscription / Connexion',
    hi: 'Bonjour, ',
    profile: 'Profil',
    favorites: 'Favoris',
    login: 'Guest Login / Signup',
    logout: 'Se Déconnecter',
    find_my_trip: 'Trouver mon voyage',
    find_your_trip: 'Trouvez votre voyage',
    my_trips: 'Mes Voyages',
    owner_login: 'Account Proprietario',

    sitemap: 'Plan du site',
    privacy: 'Vie privée',
    terms_of_service: "Conditions d'utilisation",

    your_vacation_full_time_job: 'Notre métier : vos vacances',
    your_vacation_full_time_job_note:
        'Quand vous réservez un logement auprès de Vacasa, vous avez accès à une location de vacances, mais pas que. Vous recevez aussi un service professionnel sur lequel vous pouvez compter.',

    not_trip_found: 'Aucun voyage trouvé',
    not_reservations_found_for: 'Aucune réservation trouvée pour',
    book_a_trip: 'Réserver un voyage',

    confirmation: 'Confirmation',
    last_name: 'Nom',
    find_trip_note: 'Saisissez le code indiqué dans votre e-mail de confirmation et le nom associé à votre voyage.',
    add_trip: 'Ajouter votre voyage',
    not_correct_trip_info_note: 'Ces informations de voyage ne sont pas tout à fait justes. Veuillez les vérifier et réessayer.',
    dont_see_your_trip: 'Vous ne voyez pas votre voyage ?',
    dont_see_your_trip_note: 'Ajoutez-le en utilisant le code indiqué dans votre e-mail de confirmation.',
    past_trips: 'Voyages déjà effectués',
    current_trip: 'Voyage en cours',
    need_help: 'Vous avez besoin d’aide ?',

    early_checkout_available: "L'enregistrement anticipé est disponible!",
    add_review: 'Ajouter un commentaire',
    book_again: 'Nouvelle réservation',
    late_checkout: 'Départ tardif est disponible!',
    late_checkout_purchased: 'Vous avez acheté un départ tardif.',

    find_my_trip_note: 'Saisissez le code indiqué dans votre e-mail de confirmation et le nom associé à votre voyage.',
    info_is_not_right: 'Ces informations de voyage ne sont pas tout à fait justes.',
    check_and_try_again: 'Veuillez les vérifier et réessayer.',

    item_needs_attention: 'Élément a besoin de votre attention',
    items_need_attention: 'Éléments ont besoin de votre attention',

    somethings_not_right: 'Il y a un petit problème',
    try_later_or_call: 'Veuillez essayer plus tard ou appeler Vacasa au',

    or: 'ou',

    confirmation_code: 'Code de confirmation',
    need_help_note:
        "Vous trouverez le code de votre voyage dans l'e-mail de confirmation de Vacasa, à côté du nom et de l'adresse de la maison de vacances. C'est généralement une combinaison de 8 lettres et chiffres.",
    need_help_note_third_party:
        "Veuillez noter qu'il faut saisir le code envoyé par Vacasa, car pour l’instant, nous ne pouvons pas prendre en charge les codes des tiers.",
    okay: "D'accord",

    download_app_mobile_note: "Détails du voyage, informations d'enregistrement et plus.",
    download_app_mobile_title: 'Obtenez tout dans app',

    toll_free_phone_number: '+1 844-617-6563',

    please_upgrade_browser: 'Navigateur non pris en charge',
    browser_deprecated: 'Veuillez mettre à niveau ou utiliser un autre navigateur pour afficher ce site',

    info_panel: "Panneau d'information",
    close_info_panel: "Fermer le panneau d'informations",

    open: 'Open',

    star_rating_title: 'Revoir votre séjour',
    star_rating_note: "Comment s'est passée votre expérience chez {unitName} ? Votre avis est important pour nous.",
    next: 'Next',
};

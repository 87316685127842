import React from 'react';
import { IconArrowRight } from '../../assets/icons';
import { NavigationPanel } from '..';
import './StayPanel.scss';
import { FormattedDate } from 'react-intl';
import moment from 'moment';
import { Stay, Review } from 'guest-data-types-ts-lib';

import { TripCardVariant } from '..';

interface StayPanelProps {
    stay: Stay;
    review?: Review;
    variant?: TripCardVariant;
}

export enum CheckDateTypes {
    CHECKIN = 'CHECKIN',
    CHECKOUT = 'CHECKOUT',
}

export const StayPanel: React.FC<StayPanelProps> = (props) => {
    const { stay, review, variant } = props;

    let title, date;
    const renderCheckDate = (checkType: CheckDateTypes) => {
        if (checkType === CheckDateTypes.CHECKIN) {
            title = 'check-in';
            date = stay.check_in_date_time;
        } else {
            title = 'check-out';
            date = stay.check_out_date_time;
        }

        date = new Date(date);
        const time = moment(date).format('LT');

        return (
            <div className={`col-5 p-0 ${checkType === CheckDateTypes.CHECKOUT ? 'right-content-margin' : ''}`}>
                <div className="check-title body-xs">{title}</div>
                <p className="card-stay-check-date body-bold-xs">
                    <FormattedDate value={date} month="short" day="numeric" weekday="short" />
                </p>
                <p className="card-stay-check-time body-regular">{time}</p>
            </div>
        );
    };

    return (
        <div className="card">
            <div className="card-body pt-0 pb-0">
                <div className="row card-row">
                    {renderCheckDate(CheckDateTypes.CHECKIN)}
                    <div className="col-1" style={{ padding: '0' }}>
                        <div className="divider-arrow">
                            <IconArrowRight />
                        </div>
                    </div>
                    {renderCheckDate(CheckDateTypes.CHECKOUT)}
                </div>
                <div className="row">
                    {review !== undefined && variant !== undefined && (
                        <div className="col-12 p-0">
                            <NavigationPanel stay={stay} review={review} variant={variant} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

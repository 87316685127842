import { AppStrings } from '../messages';

export const German: AppStrings = {
    language_and_currency: 'Sprache',

    property_management: 'Verwaltung von Ferienunterkünften',
    real_estate: 'Grundeigentum',
    company: 'Unternehmen',
    community: 'Gemeinschaft',
    innovation: 'Innovation',
    about_us: 'Über uns',
    careers: 'Karriere',
    newsroom: 'Newsroom',
    investors: 'Investoren',
    blog: 'Blog',
    solutions_partnerships: 'Lösungen und Partnerschaften',
    accessibility: 'Barrierefreiheit',
    leadership: 'Führung',
    social_responsibility: 'Gesellschaftliche Verantwortung',

    help: 'Hilfe',
    support_contact: 'Support und Kontakt',
    toll_free: 'Gebührenfrei:',
    local: 'Lokal:',
    covid_update: 'COVID-19 Updates',
    cancel_my_trip: 'Storniere Meine Reise',

    my_account: 'Anmelden / Registrieren',
    hi: 'Hallo, ',
    profile: 'Profil',
    favorites: 'Favoriten',
    login: 'Gast-Anmeldung / Registrierung',
    logout: 'Ausloggen',
    find_my_trip: 'Finde meine Reise',
    find_your_trip: 'Finde deine Reise',
    my_trips: 'Meine Reisen',
    owner_login: 'Hausbesitzer-Konto',

    sitemap: 'Seitenverzeichnis',
    privacy: 'Privatsphäre',
    terms_of_service: 'Nutzungsbedingungen',

    your_vacation_full_time_job: 'Wir widmen uns voll und ganz Ihrem Urlaub',
    your_vacation_full_time_job_note:
        'Wenn Sie mit Vacasa buchen, bekommen Sie mehr als nur eine Ferienunterkunft. Sie erhalten auch einen professionellen Service, auf den Sie sich verlassen können.',

    not_trip_found: 'Keine Reisen gefunden',
    not_reservations_found_for: 'Keine Buchungen gefunden für',
    book_a_trip: 'Eine Reise buchen',

    confirmation: 'Bestätigung',
    last_name: 'Nachname',
    find_trip_note: 'Geben Sie den Code aus Ihrer E-Mail-Bestätigung und den Nachnamen an, die zu dieser Reise gehören.',
    add_trip: 'Reise hinzufügen',
    not_correct_trip_info_note: 'Die Reiseinformationen stimmen nicht ganz. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    dont_see_your_trip: 'Sie können Ihre Reise nicht sehen?',
    dont_see_your_trip_note: 'Fügen Sie diese mithilfe des Codes aus Ihrer E-Mail-Bestätigung hinzu.',
    past_trips: 'Frühere Reisen',
    current_trip: 'Aktuelle Reise',
    need_help: 'Benötigen Sie Hilfe?',

    early_checkout_available: 'Früher Check-in verfügbar!',
    add_review: 'Bewertung hinzufügen',
    book_again: 'Erneut buchen',
    late_checkout: 'Später Check-out verfügbar!',
    late_checkout_purchased: 'Sie haben einen späten Check-out hinzugebucht.',

    item_needs_attention: 'Element braucht deine Aufmerksamkeit',
    items_need_attention: 'Elemente brauchen Ihre Aufmerksamkeit',

    find_my_trip_note: 'Geben Sie den Code aus Ihrer Bestätigungs-E-Mail und den Nachnamen an, die zu dieser Reise gehören.',
    info_is_not_right: 'Die Informationen stimmen nicht ganz.',
    check_and_try_again: 'Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',

    somethings_not_right: 'Da stimmt etwas nicht',
    try_later_or_call: 'Bitte versuchen Sie es später oder rufen Sie Ihren Vacasa Mitarbeiter an:',

    or: 'oder',

    confirmation_code: 'Bestätigungscode',
    need_help_note:
        'Den Code für Ihre Reise finden Sie in der Bestätigungs-E-Mail von Vacasa neben dem Namen und der Adresse des Ferienhauses. Es ist normalerweise eine Kombination aus 8 Buchstaben und Zahlen.',
    need_help_note_third_party:
        'Bitte beachten Sie, dass Sie den Code von Vacasa eingeben müssen, da wir derzeit keine Codes von Dritten verarbeiten können.',
    okay: 'Okay',

    download_app_mobile_note: 'Mit der Vacasa App ganz einfach einchecken!',
    download_app_mobile_title: 'App herunterladen',

    toll_free_phone_number: '+1 866-611-3834',

    browser_deprecated: 'Browser wird nicht unterstützt',
    please_upgrade_browser: 'Bitte aktualisieren Sie diese Website oder verwenden Sie einen anderen Browser, um sie anzuzeigen',

    info_panel: 'Informationsfenster',
    close_info_panel: 'Schließen Sie das Informationsfenster',

    open: 'Offen',

    star_rating_title: 'Bewerten Sie Ihren Aufenthalt',
    star_rating_note: 'Wie war Ihre Erfahrung bei {unitName}? Ihr Feedback ist uns wichtig.',
    next: 'Nächste',
};

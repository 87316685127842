import React from 'react';
import * as _ from 'lodash';
import { TripCard, TripCardVariant } from '..';
import { Trip, IdvStatus, MinifiedReservation } from '../../types/trip.type';
import { Trip as TripType } from 'guest-data-types-ts-lib';
import './TripGrid.scss';
import { SharedUtils } from '../../utils';

interface TripGridProps {
    title: string;
    trips: Trip[];
    variant: TripCardVariant;
    addToRefreshList: (reservationId: string) => void;
}

export const TripGrid: React.FC<TripGridProps> = (props) => {
    const { title, trips, variant, addToRefreshList } = props;

    const renderCard = (trip: Trip) => {
        const { id, attributes } = trip;

        const minifiedReservation: MinifiedReservation = {
            id: attributes.reservation_id || id,
            confirmation_code: SharedUtils.isIDVInProgressTrip(attributes)
                ? attributes.confirmation_code
                : attributes.reservation.confirmation_code || undefined,
        };

        return (
            <TripCard
                unit={SharedUtils.isIDVInProgressTrip(attributes) ? attributes.unit_overview : attributes.unit}
                stay={attributes.stay}
                review={(attributes as TripType).review}
                requiredActions={(attributes as TripType).required_actions} // TODO: forcing type, is it handled?
                reservation={minifiedReservation}
                variant={variant}
                addToRefreshList={addToRefreshList}
                idvStatus={trip.attributes.idv_status as IdvStatus}
            />
        );
    };

    if (variant === TripCardVariant.CURRENT_TRIP) {
        return (
            <React.Fragment>
                {_.map(trips, (trip, index) => (
                    <div key={index} className="row position-relative mb-5">
                        {renderCard(trip)}
                    </div>
                ))}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {variant === TripCardVariant.PAST_TRIP && <h1 className="main-title">{title}</h1>}
            <div className="row">
                {_.map(trips, (trip, index) => (
                    <React.Fragment key={index}>{renderCard(trip)}</React.Fragment>
                ))}
            </div>
        </React.Fragment>
    );
};

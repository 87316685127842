import { AccessToken, IdentityToken } from '../types/auth.type';
import { ValidationUtils } from './validation.utils';
import { StorageKeys } from '../types/keys.type';
import decode from 'jwt-decode';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { MEMORY_STORAGE } from '../App';
import { SharedUtils } from './shared.utils';

export class AuthUtils {
    public static readonly redirectLogoutUrl = `${ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL')}/idp/logout`;
    public static readonly vacasaUrl = ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL');
    public static readonly baseUrl = ValidationUtils.validateAndGetEnvVar('REACT_APP_IDP_URL');
    public static readonly loginUrl = `${AuthUtils.baseUrl}/authorize`;
    public static readonly logoutUrl = `${AuthUtils.baseUrl}/logout`;

    public static getLoginUrlParams(args?: any, state?: string): URLSearchParams {
        let redirectUrl = `${ValidationUtils.validateAndGetEnvVar('REACT_APP_REDIRECT_URI')}`;

        const utmQueryParams = AuthUtils.getUTMQueryParams();
        if (utmQueryParams.length > 0) {
            redirectUrl += `?${utmQueryParams.join('&')}`;
        }

        return new URLSearchParams({
            client_id: ValidationUtils.validateAndGetEnvVar('REACT_APP_CLIENT_ID'),
            audience: ValidationUtils.validateAndGetEnvVar('REACT_APP_AUDIENCE'),
            scope: ValidationUtils.validateAndGetEnvVar('REACT_APP_SCOPE'),
            redirect_uri: redirectUrl,
            response_type: 'token id_token',
            nonce: nanoid(),
            state: state || nanoid(),
            ...args,
        });
    }

    public static getLoginRefreshUrl = (state?: string): string => {
        const params = AuthUtils.getLoginUrlParams({ prompt: 'none' }, state);
        const url = `${AuthUtils.loginUrl}?${params.toString()}`;
        SharedUtils.setSessionStorageItem(StorageKeys.NONCE, params.get(StorageKeys.NONCE));
        SharedUtils.setSessionStorageItem(StorageKeys.STATE, params.get(StorageKeys.STATE));
        return url;
    };

    public static goToIdpLogin = () => {
        const params = AuthUtils.getLoginUrlParams();
        const url = `${AuthUtils.loginUrl}?${params.toString()}`;
        SharedUtils.setSessionStorageItem(StorageKeys.NONCE, params.get(StorageKeys.NONCE));
        SharedUtils.setSessionStorageItem(StorageKeys.STATE, params.get(StorageKeys.STATE));
        window.location.assign(url);
    };

    public static goToValidateSession = () => {
        window.location.assign(AuthUtils.getLoginRefreshUrl());
    };

    public static goToLogout = () => {
        const url = AuthUtils.redirectLogoutUrl;

        sessionStorage.clear();
        for (const key of Object.keys(MEMORY_STORAGE)) {
            MEMORY_STORAGE[key] = undefined;
        }
        window.location.assign(url);
    };

    public static goToMyTrips = () => {
        window.location.assign(AuthUtils.redirectLogoutUrl);
    };

    public static goToMyTripsLogin = () => {
        let legacyMyTripsUrl = `${AuthUtils.vacasaUrl}/login?mode=my_trips&path=/my-trips`;
        const utmQueryParams = AuthUtils.getUTMQueryParams();

        if (utmQueryParams.length > 0) {
            legacyMyTripsUrl += `&${utmQueryParams.join('&')}`;
        }

        window.location.assign(legacyMyTripsUrl);
    };

    public static getAuthFromUrl() {
        const { search } = window.location;
        const hash = window.location.hash || '';
        const searchParams = new URLSearchParams(search);
        const hashParams = new URLSearchParams(hash.replace(/^#/, ''));

        if (searchParams.has('error') || hashParams.has('access_token') || hashParams.has('id_token')) {
            return {
                error: searchParams.get('error'),
                access_token: hashParams.get('access_token'),
                id_token: hashParams.get('id_token'),
                state: hashParams.get('state'),
            };
        }

        return null;
    }

    public static isAuthorized(): boolean {
        return SharedUtils.getSessionStorageItem(StorageKeys.LEGACY_TOKEN) !== null;
    }

    public static isAuthenticated(): boolean {
        return SharedUtils.getSessionStorageItem(StorageKeys.ACCESS_TOKEN) !== null;
    }

    public static storeLegacyToken(legacyToken: string): void {
        SharedUtils.setSessionStorageItem(StorageKeys.LEGACY_TOKEN, legacyToken);
    }

    public static getLegacyToken(): string | null {
        return SharedUtils.getSessionStorageItem(StorageKeys.LEGACY_TOKEN);
    }

    public static getAccessToken(): string | null {
        return SharedUtils.getSessionStorageItem(StorageKeys.ACCESS_TOKEN);
    }

    public static getDecodedAccessToken(): AccessToken | null {
        const accessToken = AuthUtils.getAccessToken();
        return accessToken ? (AuthUtils.decodeToken(accessToken) as AccessToken) : null;
    }

    public static storeAccessToken(accessToken: string): void {
        SharedUtils.setSessionStorageItem(StorageKeys.ACCESS_TOKEN, accessToken);
    }

    public static getIdentityToken(): IdentityToken | null {
        const identityToken = SharedUtils.getSessionStorageItem(StorageKeys.IDENTITY_TOKEN);
        if (identityToken) {
            return AuthUtils.decodeToken(identityToken) as IdentityToken;
        }

        return null;
    }

    public static storeIdentityToken(identityToken: string): void {
        SharedUtils.setSessionStorageItem(StorageKeys.IDENTITY_TOKEN, identityToken);
    }

    public static decodeToken(token: string) {
        return decode(token);
    }

    public static isInsideIFrame(): boolean {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    public static removeFragmentFromUrl() {
        window.history.replaceState(null, ' ', ' ');
    }

    public static isTokenExpired(token: string | AccessToken): boolean {
        if (typeof token === 'string') {
            return this.isTokenExpired(decode(token));
        }

        const now = moment();
        const expirationDate = moment.unix(token.exp);
        const diff = now.diff(expirationDate);
        return diff >= -60000;
    }

    public static getUTMQueryParams(): string[] {
        const { search } = window.location;
        const searchParams = new URLSearchParams(search);

        const encode = encodeURIComponent;
        const queryParams = [];

        for (const [key, value] of searchParams) {
            if (key.toLowerCase().startsWith('utm_')) {
                queryParams.push(`${encode(key)}=${encode(value)}`);
            }
        }
        return queryParams;
    }
}

import { Currency, Language } from './preference.type';

export interface User {
    id: string;
    name: string;
    email: string;
    accessToken: string;
}

export interface UserState {
    user: User | null;
    isSessionExpired: boolean;
    language: Language;
    currency: Currency;
    hidePage: boolean;
}

export enum UserActions {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
    SET_CURRENCY = 'SET_CURRENCY',
    SET_LANGUAGE = 'SET_LANGUAGE',
    SET_HIDE_PAGE = 'SET_HIDE_PAGE',
}

interface UserLogIn {
    type: typeof UserActions.LOGIN;
    payload: User;
}

interface UserLogout {
    type: typeof UserActions.LOGOUT;
}

interface SessionExpired {
    type: typeof UserActions.SESSION_EXPIRED;
    payload: boolean;
}

interface SetCurrency {
    type: typeof UserActions.SET_CURRENCY;
    payload: Currency;
}

interface SetLanguage {
    type: typeof UserActions.SET_LANGUAGE;
    payload: Language;
}

interface SetHidePage {
    type: typeof UserActions.SET_HIDE_PAGE;
    payload: boolean;
}

export type UserActionTypes = UserLogIn | UserLogout | SessionExpired | SetCurrency | SetLanguage | SetHidePage;

import React from 'react';
import $ from 'jquery';
import { IconCaretDownNoRadius } from '../../../assets/icons';
import { NavbarLink } from '../../../types/nav.type';
import { TranslationUtils, ValidationUtils } from '../../../utils';
import * as _ from 'lodash';
import { companyLinks } from '../links';

const vacasaUrl = ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL');

const onKeyPress = () => {
    $('#company-dropdown-link').click();
};

const renderLink = (link: NavbarLink) => {
    return (
        <a rel="noopener noreferrer" href={link.route} id={link.id} className="nav-link-v2" key={link.id}>
            {TranslationUtils.get(link.displayText)}
        </a>
    );
};

const renderDropdownLink = (link: NavbarLink) => {
    return (
        <a rel="noopener noreferrer" href={link.route} id={link.id} className="dropdown-item pl-0">
            <span>{TranslationUtils.get(link.displayText)}</span>
        </a>
    );
};

export const LeftNavBarMenu = () => {
    return (
        <div className="navbar-nav nav-font">
            <div className="ml-0">
                {renderLink({
                    id: 'navbar-property-management',
                    route: `${vacasaUrl}/property-management`,
                    displayText: 'property_management',
                })}
            </div>
            <div className="ml-0">
                {renderLink({
                    id: 'navbar-real-estate',
                    route: `${vacasaUrl}/real-estate`,
                    displayText: 'real_estate',
                })}
            </div>
            <div className="nav-item-v2 nav-item dropdown ml-0 nav-font" tabIndex={0} onKeyPress={onKeyPress}>
                <a
                    id="company-dropdown-link"
                    className="nav-link-v2 dropdown-toggle d-flex mr-0"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <span>{TranslationUtils.get('company')}</span>
                    <IconCaretDownNoRadius className="icon-caret-down-nav-v2" />
                </a>
                <div className="dropdown-menu dropdown-menu-left" aria-labelledby="company-dropdown-link">
                    {_.map(companyLinks, (companyLink, index) => (
                        <React.Fragment key={index}>{renderDropdownLink(companyLink)}</React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

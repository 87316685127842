import React from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { Home } from './views/home/Home';
import * as _ from 'lodash';

const routes: RouteProps[] = [
    { path: '', exact: true, component: Home },
    { path: '*', component: Home },
];

export const Routes = () => {
    return (
        <Switch>
            {_.map(routes, (route, i) => {
                return <Route key={i} {...route} />;
            })}
        </Switch>
    );
};

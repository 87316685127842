import { createInstance, setLogger } from '@optimizely/react-sdk';
const sdkKey = process.env['REACT_APP_FEATURE_FLAGS_KEY'];

export class OptimizelyService {
    private static optimizely: OptimizelyService | undefined;
    private readonly optimizelyClient: any;

    private constructor() {
        this.optimizelyClient = createInstance({
            sdkKey,
        });
        setLogger(null);
    }

    public static getInstance(): OptimizelyService {
        if (!this.optimizely) {
            this.optimizely = new OptimizelyService();
        }
        return this.optimizely;
    }

    public isValid(): boolean {
        return !!this.optimizelyClient.getOptimizelyConfig();
    }

    public getClient() {
        return this.optimizelyClient;
    }
}

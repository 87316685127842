import { createIntl } from 'react-intl';
import { LANGUAGE } from '../App';
import { messages } from '../assets/translations/messages';

export class TranslationUtils {
    public static getPhoneNumbers() {
        return {
            localPhoneNumber: 'ASDFGHJKL',
            tollFreePhoneNumber: 'QWERTY',
        };
    }

    public static get(id: string, values?: { [key: string]: any }): string {
        const intl = createIntl({ locale: LANGUAGE, messages: messages[LANGUAGE] as any });
        return intl.formatMessage({ id }, values);
    }
}

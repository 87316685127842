import React from 'react';
import { Modal } from 'react-bootstrap';
import { ImageReservationNumber } from '../../assets/images';
import { TranslationUtils } from '../../utils';
import { Button } from '..';
import './NeedHelpModal.scss';

interface NeedHelpModalProps {
    show: boolean;
    handleClose: () => any;
}

export const NeedHelpModal: React.FC<NeedHelpModalProps> = (props) => {
    const { show, handleClose } = props;
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton />
            <div id={'need-help-modal'}>
                <ImageReservationNumber className={'need-help-modal-image'} />
                <div className={'need-help-modal-text'}>
                    <h4 tabIndex={0}>{TranslationUtils.get('confirmation_code')}</h4>
                    <p tabIndex={0}>{TranslationUtils.get('need_help_note')}</p>
                </div>
                <div className={'need-help-modal-buttons'}>
                    <Button
                        id="button-close-need-help"
                        className="action-button"
                        onClick={handleClose}
                        label={TranslationUtils.get('okay')}
                        variant="primary"
                        block
                    />
                    <br />
                </div>
            </div>
        </Modal>
    );
};

import React from 'react';
import { NavbarLink } from '../../../types/nav.type';
import { TranslationUtils, ValidationUtils } from '../../../utils';
import * as _ from 'lodash';
import { companyLinks } from '../links';

export const LeftMobileNavMenu: React.FC = () => {
    const localPhoneNumber = ValidationUtils.validateAndGetEnvVar('REACT_APP_LOCAL_PHONE_NUMBER');
    const vacasaUrl = ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL');
    const renderLink = (link: NavbarLink) => {
        return (
            <a rel="noopener noreferrer" href={link.route} id={link.id} className="sidenavbar-item">
                <span>{TranslationUtils.get(link.displayText)}</span>
            </a>
        );
    };

    return (
        <div id="sidenavbar-left" className="sidenavbar left-inactive fixed-top d-lg-none nav-font">
            <div className="sidenavbar-content flex-column nav-font-mobile pt-3">
                <div className="left-panel-margin">
                    <div className="mx-2">
                        {renderLink({
                            id: 'navbar-property-management-mobile',
                            route: `${vacasaUrl}/property-management`,
                            displayText: 'property_management',
                        })}
                        {renderLink({
                            id: 'navbar-real-estate-mobile',
                            route: `${vacasaUrl}/real-estate`,
                            displayText: 'real_estate',
                        })}
                    </div>
                    <div className="sidenavbar-divider" />
                    <div className="mx-3">{TranslationUtils.get('company')}</div>
                    <div className="mx-32">
                        {_.map(companyLinks, (companyLink, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {renderLink({
                                        id: `${companyLink.id}-mobile`,
                                        route: companyLink.route,
                                        displayText: companyLink.displayText,
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className="sidenavbar-divider" />
                    <div className="mx-3">{TranslationUtils.get('help')}</div>
                    <div className="mx-32">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${vacasaUrl}/support`}
                            id="navbar-support-mobile"
                            className="sidenavbar-item">
                            <span>{TranslationUtils.get('support_contact')}</span>
                        </a>
                        <a
                            id="navbar-tollfree-phone-mobile"
                            className="sidenavbar-item"
                            href={`tel:${TranslationUtils.get('toll_free_phone_number')}`}>
                            <span>{`${TranslationUtils.get('toll_free')} ${TranslationUtils.get('toll_free_phone_number')}`}</span>
                        </a>
                        <a id="navbar-local-phone-mobile" className="sidenavbar-item" href={`tel:${localPhoneNumber}`}>
                            <span>{`${TranslationUtils.get('local')} ${localPhoneNumber}`}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

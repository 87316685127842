import * as _ from 'lodash';
import ddLogger from './ddLogger';
import { DetailedError } from '../types/errors.type';

export function logPerformance(extra?: ExtrasFunction): any {
    return (target: any, methodName: string, descriptor: PropertyDescriptor) => {
        const { name: className } = target.constructor;
        const name = `${className}/${methodName}`;

        const method = descriptor.value;

        descriptor.value = function(...parameters: any[]) {
            const startTime = Date.now();

            try {
                const appliedMethodResult = method.apply(this, parameters);

                const isPromise = appliedMethodResult instanceof Promise;

                if (!isPromise) {
                    const took = Date.now() - startTime;
                    logExecution.bind(this)(name, took, parameters, appliedMethodResult, extra);
                    return appliedMethodResult;
                }

                return appliedMethodResult
                    .then((result: any) => {
                        const took = Date.now() - startTime;
                        logExecution.bind(this)(name, took, parameters, result, extra);
                        return result;
                    })
                    .catch((e: Error) => {
                        const took = Date.now() - startTime;
                        logExecution.bind(this)(name, took, parameters, null, extra, e);
                        throw e;
                    });
            } catch (e) {
                const took = Date.now() - startTime;
                logExecution.bind(this)(name, took, parameters, null, extra, e);
                throw e;
            }
        };
    };
}

function logExecution(
    name: string,
    took: number,
    args: any[],
    result: any,
    extrasFunction?: ExtrasFunction,
    error?: Error | DetailedError
): void {
    let log = `${name} took ${took}ms`;

    if (!_.isNil(error)) {
        const message = error.message;
        log += ` | ERROR: ${message}`;
        return ddLogger.error(log, { name, took, error: { kind: message, api: 'trip-api', status_code: (error as DetailedError).status } });
    }

    if (typeof extrasFunction === 'function') {
        log += ` | EXTRAS: ${extrasFunction(this, args, result)}`;
    }

    ddLogger.info(log, { name, took });
}

export type ExtrasFunction = (instance: any, args: any[], result: any) => string;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { setLanguage, setCurrency } from '../../../store/actions';

import { AuthUtils, ValidationUtils } from '../../../utils';

import { IconMenuTwo, IconUserFilled, IconXTwo } from '../../../assets/icons';
import horizontalLogo from '../../../assets/images/vacasa-logo-horizontal-blue.svg';

import { messages } from '../../../assets/translations/messages';
import languages from '../../../assets/languages.json';
import { Language, Currency, InitialLanguage } from '../../../types/preference.type';
import { StoreState } from '../../../types/store.types';
import { UserState } from '../../../types/user.store.type';

import { LeftNavBarMenu } from '../LeftNavBarMenu/LeftNavBarMenu';
import { RightNavBarMenu } from '../RightNavBarMenu/RightNavBarMenu';
import { LeftMobileNavMenu } from '../LeftMobileNavMenu/LeftMobileNavMenu';
import { RightMobileNavMenu } from '../RightMobileNavMenu/RightMobileNavMenu';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import './NavBar.scss';

export const NavBar = () => {
    const user: UserState = useSelector((store: StoreState) => store.user);
    const [rightTopNavbarIcon, setRightTopNavbarIcon] = useState('user');
    const [mobileCurrency, setMobileCurrency] = useState('');
    const [mobileLanguage, setMobileLanguage] = useState('');

    const dispatch = useDispatch();

    const caretOffset = 21;
    const translations = new Set(Object.keys(messages));

    useEffect(() => {
        setMobileLanguage(user.language.code);
        setMobileCurrency(user.currency);
    }, []);

    const handleLogoClick = () => {
        window.location.assign(ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL'));
    };

    const updateCurrentLanguage = (languageCode: string) => {
        const language = languages.find((lang) => lang.code === languageCode);
        if (language) {
            if (translations.has(language.code)) {
                setMobileLanguage(language.code);
                dispatch(setLanguage(language as Language));
            } else {
                setMobileLanguage(InitialLanguage.code);
                dispatch(setLanguage(InitialLanguage));
            }
        }
    };

    const handleLanguageChange = (languageCode: string) => {
        updateCurrentLanguage(languageCode);
    };

    const handleMobileLanguageChange = (e: any) => {
        updateCurrentLanguage(e.target.value);
        $('#language_hidden_option').html($('#language-link-mobile option:selected').text());
        $('#language-link-mobile').width($('#language_hidden_select').width()! - caretOffset);
    };

    const handleMobileCurrencyChange = (e: any) => {
        setMobileCurrency(e.target.value);
        dispatch(setCurrency(e.target.value));
        $('#currency_hidden_option').html($('#currency-link-mobile option:selected').text());
        $('#currency-link-mobile').width($('#currency_hidden_select').width()! - caretOffset);
    };

    const handleCurrencyChange = (currency: string) => {
        setMobileCurrency(currency);
        dispatch(setCurrency(currency as Currency));
    };

    const handleLogin = () => {
        AuthUtils.goToMyTrips();
    };

    const handleLogout = () => {
        AuthUtils.goToLogout();
    };

    const handleLeftMenuClick = () => {
        const navbarUser = document.getElementById('navbar-user');
        const navbar = document.getElementById('navbar-main');
        const navPanel = document.getElementById('sidenavbar-left');
        const iconMenu = document.getElementById('left-icon-menu');
        const iconClose = document.getElementById('left-icon-close');
        const iconUser = document.getElementById('right-icon-user');
        const notifications = document.getElementById('mobile-notifications');

        if (!navbarUser || !navbar || !navPanel || !iconMenu || !iconClose || !iconUser) {
            return;
        }

        navbarUser.classList.toggle('fixed-top');
        navPanel.classList.toggle('left-inactive');
        iconMenu.classList.toggle('inactive');
        iconClose.classList.toggle('inactive');
        iconUser.classList.toggle('hidden-icon');
        document.body.classList.toggle('no-scroll');
        navbar.classList.toggle('mb-5');
        if (notifications) {
            notifications.classList.toggle('hidden-icon');
        }

        if (navPanel.classList.contains('left-inactive')) {
            enableBodyScroll(navPanel);
        } else {
            disableBodyScroll(navPanel);
        }
    };

    const handleRightMenuClick = () => {
        const navbarUser = document.getElementById('navbar-user');
        const rightMenu = document.getElementById('sidenavbar-right');
        const leftIconMenu = document.getElementById('left-icon-menu');
        const notifications = document.getElementById('mobile-notifications');

        if (!navbarUser || !rightMenu || !leftIconMenu) {
            return;
        }

        navbarUser.classList.toggle('fixed-top');
        rightMenu.classList.toggle('right-inactive');
        document.body.classList.toggle('no-scroll');
        leftIconMenu.classList.toggle('hidden-icon');
        rightTopNavbarIcon === 'user' ? setRightTopNavbarIcon('close') : setRightTopNavbarIcon('user');
        if (notifications) {
            notifications.classList.toggle('hidden-icon');
        }

        if (rightMenu.classList.contains('right-inactive')) {
            enableBodyScroll(rightMenu);
        } else {
            disableBodyScroll(rightMenu);
        }
    };

    return (
        <div id="navbar-user">
            <LeftMobileNavMenu />

            <RightMobileNavMenu
                user={user}
                language={mobileLanguage}
                currency={mobileCurrency}
                onLogin={handleLogin}
                onLogout={handleLogout}
                onCurrencyChange={handleMobileCurrencyChange}
                onLanguageChange={handleMobileLanguageChange}
            />
            <nav
                id="navbar-main"
                className="navbar navbar-expand-lg navbar-light bg-white container fix-pad"
                style={{ fontFamily: '"Public Sans",sans-serif' }}>
                <span className="navbar-toggler sidenavbar-left-toggler border-0">
                    <IconMenuTwo id="left-icon-menu" className="feather icon-menu pointer" onClick={handleLeftMenuClick} />
                    <IconXTwo
                        id="left-icon-close"
                        className="feather inactive icon-menu light-stroke pointer"
                        onClick={handleLeftMenuClick}
                    />
                </span>

                <a id="navbar-vacasa-logo" className="navbar-brand-v2" onClick={handleLogoClick}>
                    <img className="d-none d-lg-block horizontal-logo-v2" src={horizontalLogo} alt="Vacasa" />
                    <img className="d-lg-none horizontal-logo-v2" src={horizontalLogo} alt="Vacasa" />
                </a>

                <span className="navbar-toggler sidenavbar-right-toggler border-0">
                    {rightTopNavbarIcon === 'user' ? (
                        <IconUserFilled id="right-icon-user" className="icon-user icon-user-nav" onClick={handleRightMenuClick} />
                    ) : (
                        <IconXTwo id="right-icon-close" className="feather" onClick={handleRightMenuClick} />
                    )}
                </span>
                <div className="collapse navbar-collapse">
                    <LeftNavBarMenu />
                    <RightNavBarMenu
                        user={user}
                        onCurrencyChange={handleCurrencyChange}
                        onLanguageChange={handleLanguageChange}
                        onLogin={handleLogin}
                        onLogout={handleLogout}
                    />
                </div>
            </nav>
        </div>
    );
};

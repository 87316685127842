export interface DetailedError extends Error {
    status: number;
    errorPrefix?: string;
}

export enum ErrorsPrefix {
    EXCEPTION = 'EX',
    IDV_FAILED = 'IF',
    NETWORK_ERROR = 'NE',
    LEGACY_TOKEN_ERROR = 'TE',
    LEGACY_TOKEN_NOT_FOUND = 'TN',
    RESERVATION_CANCELLED = 'CX',
    RESERVATION_IN_BLOCKLISTS = 'RB',
    RESERVATION_NOT_PUBLIC = 'RN',
    TRIP_API_ERROR = 'TA',
}

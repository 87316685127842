import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './index.scss';

import * as Sentry from '@sentry/browser';

import { ValidationUtils } from './utils';
import { EventHint } from '@sentry/browser';
import { TripService } from './services/trip/tripService';
import { Configuration } from './configuration';

const environment = ValidationUtils.validateAndGetEnvVar('REACT_APP_CUSTOM_NODE_ENV');
const dsn = ValidationUtils.validateAndGetEnvVar('REACT_APP_SENTRY_DSN');
const appVersion = ValidationUtils.validateAndGetEnvVar('REACT_APP_VERSION');
const appVersionContainer = document.getElementById('app-version');
if (appVersionContainer) {
    appVersionContainer.innerHTML = appVersion;
}

Sentry.init({
    release: `guests-my-trips-web:${appVersion}`,
    dsn,
    environment,
    ignoreErrors: Configuration.ignoreErrorList,
    beforeBreadcrumb(breadCrum: any): any | null {
        if (breadCrum && breadCrum.category === 'navigation') {
            breadCrum.data.to = breadCrum.data.to.substring(0, breadCrum.data.to.indexOf('token'));
        }
        if (breadCrum && breadCrum.category === 'navigation') {
            breadCrum.data.from = breadCrum.data.from.substring(0, breadCrum.data.from.indexOf('token'));
        }
        return breadCrum;
    },
    beforeSend: (event: any, hint?: EventHint): any | null => {
        if (hint && hint.originalException) {
            const error = hint.originalException;

            const message = typeof error === 'string' ? error : error.message;
            const stack = typeof error !== 'string' && error.stack ? error.stack : '';

            if (TripService.shouldIgnoreError(message) || TripService.shouldIgnoreError(stack)) {
                return null;
            }
        }
        return event;
    },
});

ReactDOM.render(<App />, document.getElementById('root'));

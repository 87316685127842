import React, { useState } from 'react';
import './TripCard.scss';
import { NavigationPanel, StayPanel } from '..';
import { TranslationUtils, ValidationUtils } from '../../utils';
import { IdvStatus, MinifiedReservation, TimeMap } from '../../types/trip.type';
import { RequiredActions, Review, Stay, Unit, UnitOverview } from 'guest-data-types-ts-lib';
import _ from 'lodash';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import segmentService from '../../services/segment/segmentService';
import ddLogger from '../../logging/ddLogger';
import { getTripTimeMap } from '../../store/actions';
import { StarRatingModal } from '../StarRatingModal/StarRatingModal';

interface TripCardProps {
    unit: Unit | UnitOverview;
    stay: Stay;
    review: Review;
    requiredActions: RequiredActions;
    variant: TripCardVariant;
    reservation: MinifiedReservation;
    addToRefreshList: (reservationId: string) => void;
    idvStatus: IdvStatus;
}

export enum TripCardVariant {
    CURRENT_TRIP = 'CURRENT_TRIP',
    UPCOMING_TRIP = 'UPCOMING_TRIP',
    PAST_TRIP = 'PAST_TRIP',
}

type CardActions = 'trip_view' | 'trip_review' | 'past_trip';

export const TripCard: React.FC<TripCardProps> = (props) => {
    const { unit, stay, variant, reservation, requiredActions, review, addToRefreshList, idvStatus } = props;
    const [isStarRatingModalShow, setIsStarRatingModalShow] = useState(false);
    const pastTrip: boolean = variant === TripCardVariant.PAST_TRIP;

    if (!unit || !stay) {
        return null;
    }

    const image = unit.photos.length > 0 && unit.photos[0] ? unit.photos[0].url : '';

    const guestPortalUrl = ValidationUtils.validateAndGetEnvVar('REACT_APP_GUEST_PORTAL_URL');

    const track = (action: CardActions) => {
        segmentService.trackEvent('My Trips', {
            action,
            reservation_id: reservation.id,
            confirmation_code: reservation.confirmation_code,
            unit_id: unit.id,
        });
    };

    const logAction = (message: string) => {
        ddLogger.info(`${message} ReservationId: ${reservation.id}`, {
            reservation_id: reservation.id,
            confirmation_code: reservation.confirmation_code,
            unit_id: unit.id,
        });
    };

    const handleClick = () => {
        let url = `${guestPortalUrl}#reservation_id=${reservation.id}`;
        const shouldReview = review && review.is_eligible && review.rating === null;
        let action: CardActions = 'trip_view';
        let logClickAction: string = 'Clicked on reservation.';
        if (variant === TripCardVariant.PAST_TRIP) {
            if (shouldReview) {
                action = 'trip_review';
                logClickAction = 'Clicked on review.';
                setIsStarRatingModalShow(true);
            } else {
                url = unit.unit_link;
                action = 'past_trip';
                logClickAction = 'Clicked on book again.';
            }
        }
        if (!shouldReview) {
            window.open(url, '_blank');
        }
        track(action);
        logAction(logClickAction);
        addToRefreshList(reservation.id);
    };

    const handleStarRatingModalDismiss = () => {
        setIsStarRatingModalShow(false);
    };
    const getNotifications = (requiredActionsReservation?: RequiredActions): number => {
        let notifications: number = idvStatus === IdvStatus.IN_PROGRESS ? 1 : 0;
        notifications = _.reduce(
            requiredActionsReservation,
            (sum, value) => (value !== undefined && !value.completed ? sum + 1 : sum),
            notifications
        );
        return notifications;
    };

    const getStyles = (cardVariant: TripCardVariant) => {
        switch (cardVariant) {
            case TripCardVariant.CURRENT_TRIP:
                return {
                    gridClass: 'col-12 col-sm-12 col-md-6 col-lg-4 current-trip-grid',
                    cardClass: 'card current-trip-card clickable',
                    imageClass: 'img-medium d-md-none',
                };

            case TripCardVariant.UPCOMING_TRIP:
                return {
                    gridClass: 'col-12 col-sm-12 col-md-6 col-lg-4 mt-5',
                    cardClass: 'card card-style clickable',
                    imageClass: 'img-medium',
                };

            case TripCardVariant.PAST_TRIP:
                return {
                    gridClass: 'col-12 col-sm-12 col-md-6 col-lg-4 mt-5',
                    cardClass: 'card card-style clickable',
                    imageClass: 'img-small',
                };
        }
    };

    const styles = getStyles(variant);
    const notifications = getNotifications(requiredActions);
    const checkInDate = moment(stay.check_in_date);

    const getUpcomingDateHighlight = () => {
        const currentDate = moment(new Date().toISOString().split('T')[0]);
        const timeMap = getTripTimeMap(stay);

        if (timeMap === TimeMap.CURRENT) {
            return TranslationUtils.get('current_trip');
        }

        if (timeMap === TimeMap.UPCOMING) {
            const isCloserThanADay = checkInDate.diff(currentDate, 'days') < 1;
            const duration = isCloserThanADay ? moment.duration(1, 'days') : moment.duration(checkInDate.diff(currentDate));
            return duration.humanize(true);
        }
        return (
            <React.Fragment>
                <FormattedDate value={new Date(stay.check_in_date_time)} month="short" day="numeric" />
                {' - '}
                <FormattedDate value={new Date(stay.check_out_date_time)} month="short" day="numeric" />
                {', '}
                <FormattedDate value={new Date(stay.check_out_date_time)} year="numeric" />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div id={`reservation-${reservation.id}`} className={styles.gridClass}>
                <div className={styles.cardClass} tabIndex={0} role={'link'} onKeyPress={handleClick} onClick={handleClick}>
                    <img className={styles.imageClass} src={`${image}?w=360`} alt="" />
                    <div className="card-body">
                        <div>
                            <small className="card-date body-xs">{getUpcomingDateHighlight()}</small>
                        </div>
                        <div>
                            <small
                                className={`card-location body-bold-xs`}>{`${unit.city}, ${unit.state}`}</small>
                        </div>
                        {pastTrip ? (
                            <h4 className="card-title-past-trip heading-med">{unit.name}</h4>
                        ) : (
                            <h4 className="card-title heading-med">{unit.name}</h4>
                        )}
                        {variant !== TripCardVariant.PAST_TRIP && <StayPanel stay={stay} review={review} variant={variant} />}
                        {variant === TripCardVariant.PAST_TRIP && (
                            <React.Fragment>
                                <br />
                                <NavigationPanel stay={stay} review={review} variant={variant} />
                            </React.Fragment>
                        )}
                        {notifications > 0 && variant !== TripCardVariant.PAST_TRIP && (
                            <div className="ml-2 mt-2">
                                <span className="badge badge-pill badge-danger">{notifications}</span>
                                <span className="ml-2">
                                    {notifications === 1
                                        ? TranslationUtils.get('item_needs_attention')
                                        : TranslationUtils.get('items_need_attention')}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {variant === TripCardVariant.CURRENT_TRIP && (
                <div
                    className="col-md-6 col-lg-8 position-absolute card-bg-img d-none d-md-block clickable"
                    onClick={handleClick}
                    onKeyPress={handleClick}
                    style={{
                        background: `url(${image}?w=1200) center center`,
                    }}
                />
            )}
            <StarRatingModal
                reservation={reservation}
                unit={unit}
                review={review}
                show={isStarRatingModalShow}
                handleClose={handleStarRatingModalDismiss}
            />
        </React.Fragment>
    );
};

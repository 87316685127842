import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './StarRatingModal.scss';
import { TranslationUtils } from '../../utils';
import { ImageStarRating } from '../../assets/images';
import { Button } from '..';
import Rating from 'react-rating';
import { IconStarRatingGrey, IconStarRatingYellow } from '../../assets/icons';
import { MinifiedReservation } from '../../types/trip.type';
import { Review, Unit, UnitOverview } from 'guest-data-types-ts-lib';

interface StarRatingModalProps {
    reservation: MinifiedReservation;
    unit: Unit | UnitOverview;
    review: Review;
    handleClose: () => any;
    show: boolean;
}

export const StarRatingModal: React.FC<StarRatingModalProps> = (props) => {
    const { show, handleClose, reservation, unit, review } = props;
    const [rate, setRate] = useState<number>(0);

    const handleRedirectFeedback = () => {
        // 1 Good disposition == Rating then review // 0 Bad Disposition == Review then rating
        const disposition: number = rate >= 3 ? 1 : 0;
        const view: string = disposition === 0 ? 'review' : 'rate';
        let url: string = `${review.review_base_url}&view=${view}&disposition=${disposition}&utm_content=c-star_${rate}&star-rating=${rate}&utm_review=${reservation.id}&utm_source=my-trips&utm_medium=web`;
        window.open(url, '_blank');
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton />
            <div id="star-rating-modal">
                <ImageStarRating className="star-rating-modal-image" />
                <div className="star-rating-modal-text">
                    <h4>{TranslationUtils.get('star_rating_title')}</h4>
                    <div className="mb-4" />
                    <p>{TranslationUtils.get('star_rating_note', { unitName: unit.name })}</p>
                    <Rating
                        onChange={(rate) => setRate(rate)}
                        initialRating={rate}
                        fullSymbol={<IconStarRatingYellow className="star-icon" />}
                        emptySymbol={<IconStarRatingGrey className="star-icon" />}
                    />
                    <br />
                    <br />
                </div>
                <div className="star-rating-modal-buttons">
                    <Button
                        id="button-send-feedback"
                        onClick={handleRedirectFeedback}
                        label={TranslationUtils.get('next')}
                        variant="primary"
                        block
                        disabled={rate <= 0}
                    />
                </div>
                <br />
            </div>
        </Modal>
    );
};

import React from 'react';
import $ from 'jquery';
import { IconCaretDownNoRadius } from '../../../assets/icons';
import languages from '../../../assets/languages.json';
import { UserState } from '../../../types/user.store.type';
import { TranslationUtils, ValidationUtils } from '../../../utils';
import * as _ from 'lodash';

interface RightMobileNavMenuProps {
    user: UserState;
    currency: string;
    language: string;
    onLogin: () => void;
    onLogout: () => void;
    onLanguageChange: (e: any) => void;
    onCurrencyChange: (e: any) => void;
}

export const RightMobileNavMenu: React.FC<RightMobileNavMenuProps> = (props) => {
    const vacasaUrl = ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL');
    const ownerPortalURL = process.env['REACT_APP_OWNER_PORTAL_URL'];
    const { user, language, onLogin, onLogout, onLanguageChange } = props;

    const renderMobileLink = (id: string, path: string, displayText: string) => {
        return (
            <a id={id} className="sidenavbar-item" rel="noopener noreferrer" href={path}>
                <span>{displayText}</span>
            </a>
        );
    };

    const caretOffset = 21;
    $('#language-link-mobile').width($('#language_hidden_select').width()! - caretOffset);
    $('#currency-link-mobile').width($('#currency_hidden_select').width()! - caretOffset);
    return (
        <div id="sidenavbar-right" className="sidenavbar right-inactive fixed-top d-lg-none">
            <div className="sidenavbar-content nav-font-mobile">
                {user.user ? (
                    <React.Fragment>
                        <div className="mx-2 account-name">
                            {TranslationUtils.get('hi')}
                            {user.user.name}
                        </div>
                        <div className="mx-32">
                            {renderMobileLink('navbar-trip-mobile', `${vacasaUrl}/my-trips`, TranslationUtils.get('my_trips'))}
                            {renderMobileLink('navbar-favorites-mobile', `${vacasaUrl}/favorites`, TranslationUtils.get('favorites'))}
                            {renderMobileLink('navbar-profile-mobile', `${vacasaUrl}/my-profile`, TranslationUtils.get('profile'))}
                        </div>
                        <div className="sidenavbar-divider" />
                        <div className="mx-32">
                            {renderMobileLink('navbar-owner-login-mobile', `${ownerPortalURL}/login`, TranslationUtils.get('owner_login'))}
                        </div>
                        <div className="sidenavbar-divider" />
                        <div className="mx-32">
                            <a id="navbar-favorites-mobile" className="sidenavbar-item" href="#/" onClick={onLogout}>
                                <span>{TranslationUtils.get('logout')}</span>
                            </a>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="mx-2">{TranslationUtils.get('my_account')}</div>
                        <div className="mx-32 mr-32">
                            <div className="pill">
                                <a id="navbar-login-mobile" className="sidenavbar-item" href="#/" onClick={onLogin}>
                                    {TranslationUtils.get('login')}
                                </a>
                            </div>
                            {renderMobileLink('navbar-owner-login-mobile', `${ownerPortalURL}/login`, TranslationUtils.get('owner_login'))}
                            {renderMobileLink('navbar-trip-login-mobile', `${vacasaUrl}/my-trips`, TranslationUtils.get('my_trips'))}
                        </div>
                    </React.Fragment>
                )}
                <div className="sidenavbar-divider" />
                <div className="mx-2 pl-1">{TranslationUtils.get('language_and_currency')}</div>
                <div className="mx-32 mt-2">
                    <div className="language-currency-dropdown sidenavbar-select-border">
                        <select
                            id="language-link-mobile"
                            className="sidenavbar-item collapse-toggle d-flex sidenavbar-select nav-font-mobile"
                            onChange={onLanguageChange}
                            value={language}>
                            {_.map(languages, (languageOption, index) => {
                                return (
                                    <option key={index} value={languageOption.code} className={'nav-font-mobile'}>
                                        {languageOption.name}
                                    </option>
                                );
                            })}
                        </select>
                        <IconCaretDownNoRadius className="icon-caret-down-nav-v2 mb-2" />
                    </div>
                </div>
            </div>
        </div>
    );
};

import { ValidationUtils } from '../../utils';
import { NavbarLink } from '../../types/nav.type';

const vacasaUrl = ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL');

export const companyLinks: NavbarLink[] = [
    {
        id: 'navbar-aboutus',
        route: `${vacasaUrl}/about-us`,
        displayText: 'about_us',
    },
    {
        id: 'navbar-leadership',
        route: `${vacasaUrl}/leadership`,
        displayText: 'leadership',
    },
    {
        id: 'navbar-innovation',
        route: `${vacasaUrl}/innovation`,
        displayText: 'innovation',
    },
    {
        id: 'navbar-newsroom',
        route: `${vacasaUrl}/press`,
        displayText: 'newsroom',
    },
    {
        id: 'navbar-investors',
        route: `${vacasaUrl}/investors`,
        displayText: 'investors',
    },
    {
        id: 'navbar-social-responsibility',
        route: `${vacasaUrl}/social-responsibility`,
        displayText: 'social_responsibility',
    },
    {
        id: 'navbar-management-solutions',
        route: `${vacasaUrl}/property-management/solutions`,
        displayText: 'solutions_partnerships',
    },
    {
        id: 'navbar-blog',
        route: `${vacasaUrl}/blog`,
        displayText: 'blog',
    },
    {
        id: 'navbar-careers',
        route: `${vacasaUrl}/careers`,
        displayText: 'careers',
    },
];

export const communityLinks: NavbarLink[] = [
    {
        id: 'navbar-social-responsibility',
        route: `${vacasaUrl}/social-responsibility`,
        displayText: 'social_responsibility',
    },
    {
        id: 'navbar-accessibility',
        route: `${vacasaUrl}/accessibility`,
        displayText: 'accessibility',
    },
];

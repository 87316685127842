import { Trip, TimeMap } from './trip.type';
import { DetailedError } from './errors.type';

export interface TripsState {
    isFetchTripsLoading: boolean;
    currentTrips: Trip[];
    upcomingTrips: Trip[];
    pastTrips: Trip[];
    fetchTripsError: DetailedError | null;
    isLoaded: boolean;
    isShowingFindMyTripsModal: boolean;
    addTripError: Error | null;
    isAddTripLoading: boolean;
}

export enum TripsActions {
    SET_FETCH_TRIPS_LOADING = 'SET_FETCH_TRIPS_LOADING',
    SET_FETCH_TRIPS_ERROR = 'SET_FETCH_TRIPS_ERROR',
    ADD_TRIP = 'ADD_TRIP',
    SET_ADD_TRIP_LOADING = 'SET_ADD_TRIP_LOADING',
    SET_ADD_TRIP_ERROR = 'SET_ADD_TRIP_ERROR',
    SET_TRIP = 'SET_TRIP',
    UPDATE_TRIP = 'UPDATE_TRIP',
    SET_IS_SHOWING_FIND_MY_TRIP_MODAL = 'SET_IS_SHOWING_FIND_MY_TRIP_MODAL',
    SET_TRIPS_LOADED = 'SET_TRIPS_LOADED',
    UPDATE_UPCOMING_TRIPS = 'UPDATE_UPCOMING_TRIPS',
    REMOVE_CANCELLED_RESERVATION = 'REMOVE_CANCELLED_RESERVATION',
}

interface SetFetchTripsLoading {
    type: typeof TripsActions.SET_FETCH_TRIPS_LOADING;
    payload: boolean;
}

interface SetFetchTripsError {
    type: typeof TripsActions.SET_FETCH_TRIPS_ERROR;
    payload: DetailedError | null;
}

interface AddTrip {
    type: typeof TripsActions.ADD_TRIP;
    payload: {
        id: string;
        last_name: string;
    };
}

interface SetAddTripLoading {
    type: typeof TripsActions.SET_ADD_TRIP_LOADING;
    payload: boolean;
}

interface SetAddTripError {
    type: typeof TripsActions.SET_ADD_TRIP_ERROR;
    payload: Error | null;
}

interface SetTrip {
    type: typeof TripsActions.SET_TRIP;
    payload: { type: TimeMap; trip: Trip };
}

interface UpdateTrip {
    type: typeof TripsActions.UPDATE_TRIP;
    payload: { type: TimeMap; trip: Trip };
}

interface SetIsShowingFindMyTripModal {
    type: typeof TripsActions.SET_IS_SHOWING_FIND_MY_TRIP_MODAL;
    payload: boolean;
}

interface SetTripsLoaded {
    type: typeof TripsActions.SET_TRIPS_LOADED;
    payload: boolean;
}

interface UpdateUpcomingTrips {
    type: typeof TripsActions.UPDATE_UPCOMING_TRIPS;
}

interface RemoveCancelledReservation {
    type: typeof TripsActions.REMOVE_CANCELLED_RESERVATION;
    payload: string;
}

export type TripActionTypes =
    | SetFetchTripsLoading
    | SetFetchTripsError
    | AddTrip
    | SetTrip
    | UpdateTrip
    | SetAddTripLoading
    | SetAddTripError
    | SetIsShowingFindMyTripModal
    | SetTripsLoaded
    | UpdateUpcomingTrips
    | RemoveCancelledReservation;

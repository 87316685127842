import React  from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { Provider, useSelector } from 'react-redux';
import store from './store/store';
import { IntlProvider } from 'react-intl';
import { messages } from './assets/translations/messages';
import { Footer } from './components/Footer/Footer';
import { NavBar } from './components/NavBar';
import { FindMyTripModal } from './components';
import { AuthUtils } from './utils';
import { StoreState } from './types/store.types';
import { ErrorModal } from './components/ErrorModal/ErrorModal';
import { LanguageCodes } from './types/preference.type';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { Authentication } from './auth/Authentication';
import { customAlphabet } from 'nanoid';
import { OptimizelyService } from './services/optimizely/optimizelyService';

const nanoid = customAlphabet('ABCDEFGHJKLMNPQRSTUVWXYZ1234567890', 8);
export const FLOW_ID = nanoid();
export const MEMORY_STORAGE: { [key: string]: any } = {};
export let LANGUAGE = 'en';

const InternationalApp: React.FC = () => {
    const { user } = useSelector((storeState: StoreState) => storeState);
    const language = user.language.code === LanguageCodes.ES_LA ? 'es' : user.language.code;

    LANGUAGE = language;

    return (
        <IntlProvider locale={language} messages={messages[language] as any}>
            <div className={user.hidePage ? 'hide-page' : ''}>
                <Row>
                    <NavBar />
                </Row>
                <Row>
                    <Col id="main-content" xs={12} sm={12} md={12}>
                        <Routes />
                    </Col>
                </Row>
                <FindMyTripModal />
                <ErrorModal />
                <Footer />
            </div>
        </IntlProvider>
    );
};

const optimizely = OptimizelyService.getInstance().getClient();

const App: React.FC = () => {
    return (
        <OptimizelyProvider optimizely={optimizely} user={{ id: nanoid() }}>
            <Container>
                <Provider store={store}>
                    <BrowserRouter>
                        <Authentication />
                        {AuthUtils.isInsideIFrame() ? <React.Fragment /> : <InternationalApp />}
                    </BrowserRouter>
                </Provider>
            </Container>
        </OptimizelyProvider>
    );
};

export default App;

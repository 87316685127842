import cn from 'classnames';
import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import './Button.scss';

type VariantType = 'primary' | 'info' | 'danger' | 'default' | 'banner';
type ButtonType = 'button' | 'submit' | 'reset';
type SizeType = 'sm' | 'lg';

interface ButtonProps {
    id?: string;
    label: any;
    onClick: (anything?: any) => void;
    variant?: VariantType;
    type?: ButtonType;
    size?: SizeType;
    disabled?: boolean;
    block?: boolean;
    className?: string;
    isLoading?: boolean;
}

const classes = {
    danger: 'vacasa-btn-danger',
    default: 'vacasa-btn-default',
    info: 'vacasa-btn-info',
    primary: 'vacasa-btn-primary',
    banner: 'vacasa-btn-app-banner',
};

export const Button: React.FC<ButtonProps> = (props) => {
    const { label, isLoading, variant, className, ...rest } = props;
    const vacasaVariant = variant ? classes[variant] : classes.default;

    let buttonContent = label;
    if (isLoading) {
        buttonContent = (
            <div>
                &nbsp;
                <div className={'loader-dots loader-dots--sm loader-dots--midnight'}>
                    <div className={'bounce1'}></div>
                    <div className={'bounce2'}></div>
                    <div className={'bounce3'}></div>
                </div>
            </div>
        );
    }

    return (
        <BootstrapButton className={cn(className && className, `${vacasaVariant} body-regular`)} {...rest}>
            {buttonContent}
        </BootstrapButton>
    );
};

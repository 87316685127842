import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, NeedHelpModal } from '..';
import { setIsShowingFindMyTripModal } from '../../store/actions';
import { ImageCatOnSuitcases } from '../../assets/images';
import { TranslationUtils, ValidationUtils } from '../../utils';

import { UserState } from '../../types/user.store.type';
import './NoTripsFound.scss';

export enum NoTripsFoundVariant {
    NO_TRIPS = 'NO_TRIPS',
    ADD_TRIP = 'ADD_TRIP',
}

interface NoTripsFoundProps {
    variant: NoTripsFoundVariant;
}

export const NoTripsFound: React.FC<NoTripsFoundProps> = (props) => {
    const { variant } = props;
    const [isShowingPossibleReasonsModal, setIsShowingPossibleReasonsModal] = useState(false);
    const storeUser: UserState = useSelector((store: { user: UserState }) => store.user);
    const dispatch = useDispatch();

    if (!storeUser.user) {
        return null;
    }

    const vacasaURL = ValidationUtils.validateAndGetEnvVar('REACT_APP_VACASA_URL');

    return (
        <React.Fragment>
            <div
                id={'no-trip-found-grid'}
                className={variant === NoTripsFoundVariant.NO_TRIPS ? 'no-trips-found' : 'no-trips-found-enfasis'}>
                <div className="no-trips-found-image">
                    <ImageCatOnSuitcases />
                </div>
                <div>
                    <h1 className={'no-trips-found-modal-text heading-large'}>{TranslationUtils.get('dont_see_your_trip')}</h1>
                    <p className={'no-trips-found-modal-note body-regular'}>
                        {TranslationUtils.get('dont_see_your_trip_note')}
                        <br />
                        <a
                            id={'link-need-help'}
                            tabIndex={0}
                            aria-label={`${TranslationUtils.get('dont_see_your_trip')} ${TranslationUtils.get(
                                'dont_see_your_trip_note'
                            )} ${TranslationUtils.get('need_help')}`}
                            href=""
                            onClick={(event) => {
                                event.preventDefault();
                                setIsShowingPossibleReasonsModal(true);
                            }}
                            onKeyPress={(event) => {
                                event.preventDefault();
                                setIsShowingPossibleReasonsModal(true);
                            }}>
                            {TranslationUtils.get('need_help')}
                        </a>
                    </p>
                    <div className="no-trips-found-buttons body-regular">
                        {variant === NoTripsFoundVariant.NO_TRIPS ? (
                            <React.Fragment>
                                <Button
                                    id={'button-add-trip-new'}
                                    variant="primary"
                                    label={TranslationUtils.get('add_trip')}
                                    onClick={() => {
                                        dispatch(setIsShowingFindMyTripModal(true));
                                    }}
                                />
                                <Button
                                    id={'button-book-trip-new'}
                                    variant="info"
                                    label={TranslationUtils.get('book_a_trip')}
                                    onClick={() => {
                                        window.location.assign(vacasaURL);
                                    }}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button
                                    id={'button-add-trip'}
                                    variant="primary"
                                    label={TranslationUtils.get('add_trip')}
                                    onClick={() => {
                                        dispatch(setIsShowingFindMyTripModal(true));
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
            <NeedHelpModal
                show={isShowingPossibleReasonsModal}
                handleClose={() => {
                    setIsShowingPossibleReasonsModal(false);
                }}
            />
        </React.Fragment>
    );
};

import React from 'react';
import { ImageVacasa } from '../../assets/images';
import './Banner.scss';
import { IconStarBanner, IconXSmall } from '../../assets/icons';
import { Button } from '../Button/Button';
import { TranslationUtils } from '../../utils';
import segmentService from '../../services/segment/segmentService';

interface BannerProps {
    show: boolean;
    handleClose: () => any;
}

export const Banner: React.FC<BannerProps> = (props) => {
    const { show, handleClose } = props;
    const bannerURL = process.env['REACT_APP_MOBILE_BANNER_URL'] as string;

    const renderStars = (): JSX.Element => {
        return (
            <>
                {Array(5)
                    .fill(null)
                    .map((_, index) => (
                        <IconStarBanner className={'icon-banner'} key={index} />
                    ))}
            </>
        );
    };

    const renderMessages = (): JSX.Element => {
        return (
            <div className="banner-download-app-message">
                <span id="banner-text-join">{TranslationUtils.get('download_app_mobile_title')}</span>
                <br />
                <span id="banner-text-note">{TranslationUtils.get('download_app_mobile_note')}</span>
                <br />
                <span role="img" aria-label="5 stars" className="d-flex">
                    {renderStars()}
                    <span id="banner-text-star">4k</span>
                </span>
            </div>
        );
    };

    const handleOpen = (): void => {
        segmentService.trackEvent('Trip Portal Banner', { banner_name: 'Mobile_App_Banner', url: bannerURL });
        window.location.href = bannerURL;
    };

    return (
        <div id={'banner-download-app-main'} className={show ? 'banner-download-app d-md-none' : 'hide-page'}>
            <div className="banner-download-app-close">
                <a
                    aria-label={'close'}
                    id={'link-close-app-banner'}
                    tabIndex={0}
                    role={'button'}
                    onKeyPress={() => {
                        handleClose();
                    }}
                    onClick={() => {
                        handleClose();
                    }}>
                    <IconXSmall />
                </a>
            </div>
            <div className="banner-download-app-image">
                <ImageVacasa className={'image-app-banner'} />
            </div>
            {renderMessages()}
            <div className="banner-download-app-open">
                <Button
                    id="button-open-app-download"
                    className={'banner-button-open'}
                    variant="banner"
                    label={TranslationUtils.get('open')}
                    onClick={() => {
                        handleOpen();
                    }}
                />
            </div>
        </div>
    );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { TranslationUtils, ValidationUtils } from '../../utils';
import { StoreState } from '../../types/store.types';
import { ImageUnplugged } from '../../assets/images';
import './ErrorModal.scss';
import { FLOW_ID } from '../../App';
import { ErrorsPrefix } from '../../types/errors.type';

export const ErrorModal: React.FC = () => {
    const { fetchTripsError } = useSelector((store: StoreState) => store.trips);

    let localPhoneNumber = ValidationUtils.validateAndGetEnvVar('REACT_APP_LOCAL_PHONE_NUMBER');

    const handleClose = () => null;

    const blurEverything = () => {
        const app = document.getElementById('routed-app');
        if (app) {
            app.setAttribute('class', 'blur');
        }
    };

    const shouldShowErrorModal = !!fetchTripsError;
    const errorPrefix = fetchTripsError?.errorPrefix ? fetchTripsError?.errorPrefix : ErrorsPrefix.EXCEPTION;

    return (
        <Modal id="error-modal" show={shouldShowErrorModal} onHide={handleClose} onEnter={blurEverything}>
            <Modal.Header />

            <div className="error-modal-body">
                <div>
                    <ImageUnplugged className="error-modal-image" />
                </div>
                <h4>{TranslationUtils.get('somethings_not_right')}</h4>

                <div className="error-modal-instructions">
                    <p>{TranslationUtils.get('try_later_or_call')}</p>
                    <span>
                        [ <a href={`tel:${localPhoneNumber}`}>{localPhoneNumber}</a> ]
                    </span>

                    <div className="error-flow-id">
                        <span className="text-muted">{`ref: ${errorPrefix}-${FLOW_ID}`}</span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
